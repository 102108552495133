/* ======================================== */
/* Block Tabs */
/* ======================================== */
@use "abstracts" as *;

.block-tabs {
    display: block;
    max-width: calc(100vw - 2rem);
}

.tabs {
    display: flex;
    flex-wrap: wrap; // make sure it wraps
    gap: 0.25rem;

    li {
        list-style-type: none;
        margin: 0;
    }

    &__content {
        padding:40px;
        background-color:mix($ocean-depths,white, 10%);
    }

    a {
        text-decoration: none;

        &:hover, &:focus, &:active { 
            text-decoration: none;
        }
    }

    span {
        order: 1; // Put the labels first
        display: block;
        padding: 1rem 2rem;
        margin-right: 0.2rem;
        cursor: pointer;
        background-color:mix($ocean-depths,white, 10%);
        font-weight: bold;
        transition: background ease 0.2s;
        margin-bottom: 0;
        text-decoration: none;
        min-width: 60px;
        text-align: center;
    }

    & .inactive span {
        background-color: $ocean-depths;
        color: $white;
    }

    .tab {
        order: 99; // Put the tabs last
        flex-grow: 1;
        width: 100%;
        display: none;
        padding: 1rem;
        background: #fff;
    }
}

// MIGHT NEED SORTED

.tabs + span {
	background: #fff;
}
.tabs + span + .tab {
	display: block;
}

@media (max-width: 45em) {
  .tabs .tab,
  .tabs label {
    order: initial;
  }
  .tabs label {
    width: 100%;
    margin-right: 0;
    margin-top: 0.2rem;
  }
}
