@use "abstracts" as *;
@use 'sass:color';

.articles {
    @extend %content-vertical-spacing;
    // @extend %content-horizontal-spacing;

    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 2rem;

        .btn {
            margin-top: 1rem;
            
            @include breakpoint(xs) {
                margin-top: 0;
            }
        }
    }

    &__heading {
        margin: 0;
        color: var(--t-link);
    }
}

.articles-slider {
    margin-left: -1rem;
    margin-bottom: 1rem;
    width: calc(100% + 2rem);

    &__item {
        margin: 0 1rem;
        overflow: hidden;
    }

    .slick-dots {
        position: relative;
        display: flex;
        justify-content: center;
        gap: 10px;
        justify-content: center;
        
        li {
            width: 15px;
            height: 15px;
            background: var(--t-content, black);
            border-radius: 50%;
            cursor: pointer;

            &.slick-active {
                border: solid 1px black;
                background: transparent !important;
            }
        }

        button {
            display: none;
        }
    }
}

.articles-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    // This should match slider breakpoints.
    @include breakpoint(sm) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
}
    


