@use '../abstracts/' as *;

.cta {
    display: flex;
    align-items: stretch;
    width: 100%;
    background-color: var(--t-bg);

    &__wrapper {
        flex-grow: 1;
        align-self: center;
        padding:40px;
    }

    &__heading {
        color: var(--t-content);
        font-size: 1.5rem;
    }

    &__content {
        color: var(--t-content);

        p {
            margin-bottom: 0;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 7rem;
        height: 100%;
        min-height: 10rem;
        background-color: var(--t-bg);

        svg {
            width: 2rem;
            height: 2rem;

            .theme-icon-bg {
                fill: var(--t-btn-bg);
            }
        }
    }

    &__link {
        transition: all 0.5s ease-in-out;

        &:hover {
            svg {
                .theme-icon-bg {
                    fill: var(--t-btn-bg--action);
                }
            }
        }
    }
}