.blockquote-wrapper {

}

.blockquote {
    position: relative;
    padding: 2.5rem;
    background: rgb(16, 109, 138, 75%);

    &::after {
        content: "";
        width: 60px;
        height: 40px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        transform: translateY(100%);
        background: rgb(16, 109, 138, 75%);
        clip-path: polygon(0 0, 0% 100%, 100% 0);
    }

    &__caption {
        padding: 1rem 1rem 1rem 70px; // Make space for :after item.
        text-align: right;
    }

    &__author {
        display: inline;
    }

    &__cite {
        font-style: normal;
    }
}