/* ======================================== */
/* btn */
// looks like a button but isn't a <button> element
/* ======================================== */

@use "abstracts" as * ;

.btn {
    display: inline-block;
    padding: 1rem 2rem;
    border: 2px solid;
    border-radius: 3px;
    text-decoration: none;
    font-weight: 900;
    text-transform: uppercase;

    transition: all 0.3s ease-in-out;

    &--fill {
        border-color: var(--t-btn-bg);
        background-color: var(--t-btn-bg);
        color: var(--t-btn-content);

        &:hover,
        &:focus {
            border-color: var(--t-btn-bg--action);
            background-color: var(--t-btn-bg--action);
            color: var(--t-btn-content--action);
        }
    }

    &--outline {
       border-color: var(--t-btn-outline-border);
        background-color: var(--t-btn-outline-bg);
        color: var(--t-btn-outline-content);

        &:hover,
        &:focus {
            border-color: var(--t-btn-outline-border--action);
            background-color: var(--t-btn-outline-bg--action);
            color: var(--t-btn-outline-content--action);
        }
    }
}

