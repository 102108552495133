// move to video scss
.basic-video {
    display: block;         // Remove small gap below video tag.
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.basic-video-embed {
    position: relative;

    iframe {
        max-width: 100%;
    }
}