/* ---------------------------------------------------------------------------------*/
/*  CONTACT FORM  */
/* ---------------------------------------------------------------------------------*/
@use '../../abstracts' as *;
@use 'components/links/_btn.scss' as *;
@use "sass:math";

$contact-form--margin:20px;

.contact-form {
    display: flex;
    flex-direction: column;
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;

    @include breakpoint(md) {
        justify-content: space-between;
    }

    // Column only
    &__col {
        flex-basis: 100%;
        width: 100%;
       
        @include breakpoint(md) {
            flex-basis: calc(50% - #{$contact-form--margin}*2);
            width: calc(50% - #{$contact-form--margin}*2);
        }
    }

    //
    // Forms
    // --------------------------------------------------

    // Sizing
    $input-padding: 1em;
    $form-font-size: 1rem; // 16px

    // textbox, textarea and select
    $input-border-color: var(--t-content);
    $input-border-color--highlight: var(--t-content--action);

    // radio and checkbox
    $radio-checkbox-size: $form-font-size*1.5;
    $radio-checkbox-indent: $radio-checkbox-size + 20;
    $radio-checkbox-border-color: var(--t-content);
    $radio-checkbox-border-color--highlight: var(--t-content--action);
    $radio-checkbox-bg-color: $white;
    $radio-checkbox-bg-color--highlight: var(--t-content--action);
    $radio-checkbox-tick-color: $white;

    $input-text-color: var(--t-content);

    $label-text-color: var(--t-content);
    $label-text-highlight-color: var(--t-content--action);

    *:focus{
        outline:none;
    }

    input[type=text],
    input[type=url],
    input[type=tel],
    input[type=number],
    input[type=color],
    input[type=email],
    input[type=password],
    .textbox,
    select,
    textarea {
        display: block;
        -webkit-appearance: none;
        background-color: $white;
        padding: $input-padding;
        width: 100%;
        font-family: $font-stack;
        font-size: $form-font-size;
        font-weight: 600;
        line-height: $form-font-size * 1.4;
        color: $input-text-color;
        border-width:  2px ; // edited 0 0 2 0 
        border-radius: 0;
        transition: all 0.2s 0s;

        &:hover,
        &:focus {
            border-color: $input-border-color--highlight;
            outline: none;
        }

        &:invalid {
            box-shadow: none !important;
        }

        // Placeholders need to be defined separately for each browser.
        // If paired together certain browsers ignore the whole thing.

        .placeholder {
            color: $input-text-color;
            font-family: $font-stack !important;
            opacity: 1 !important;
        }

        /* WebKit, Blink, Edge */
        &::-webkit-input-placeholder { @extend .placeholder; }
        /* Mozilla Firefox 4 to 18 */
        &:-moz-placeholder { @extend .placeholder; }
        /* Mozilla Firefox 19+ */
        &::-moz-placeholder { @extend .placeholder; }
        /* Internet Explorer 10-11 */
        &:-ms-input-placeholder { @extend .placeholder; }
        /* Microsoft Edge */
        &::-ms-input-placeholder { @extend .placeholder; }
        /* Most modern browsers support this now. */
        &::placeholder { @extend .placeholder; }
    }

    textarea {
        padding-bottom: $input-padding*1.5;
    }


    /* -------------------------------------*/
    /* BUTTON */
    /* -------------------------------------*/

    button,
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {

        display: inline-block;
        padding: 1rem 2rem;
        border: 2px solid;
        border-radius: 3px;
        text-decoration: none;
        font-weight: 900;
        text-transform: uppercase;

        transition: all 0.3s ease-in-out;
        border-color: var(--t-btn-border);
        background-color: var(--t-btn-bg);
        color: var(--t-btn-content);

        &:hover,
        &:focus {
            border-color: var(--t-btn-border--action);
            background-color: var(--t-btn-bg--action);
            color: var(--t-btn-content--action);
            cursor: pointer;
        }
    

        @include breakpoint(mobile) {
            width: 100%;
        }

    }





    /* -----------------------------------------------------------------------*/
    /* FORM BUILDER STYLING */
    /* This is a little hacky and is designed to work with FormBuilder markup */
    /* -----------------------------------------------------------------------*/

    // label for communication preferences fieldset
    .InputfieldFieldset legend {
        font-weight: bold;
    }

    label {
        font-weight: normal;
    }

    /* -------------------------------------*/
    /* Formbuilder element wrappers */

    .Inputfield {
        position: relative;
        display: block;
        margin-top: $input-padding*1.5;

        @include breakpoint(mobile-max) {
            width: 100% !important;
        }
    }


    .InputfieldColumnWidth {
        display: inline-block;
    }


    .InputfieldContent {
        position: relative;

        .form-item-group & {
            margin-top: 0;
        }

        .description {
            margin-bottom: math.div($input-padding, 2);
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
    }

    .InputfieldContent .error,
    .parsley-errors-list.filled {
        // position: absolute; // don't change this
        // top: 100%;
        display: inline-block;
        line-height: 100%;
        background: $red;
        color: $white !important;
        padding: 0.25em 0.5em;
        font-weight: 500;
        font-size: 0.75em;
        margin-bottom: 1em;

        li:last-child {
            margin-bottom: 0;
        }
    }


    /* -------------------------------------*/
    /* Textbox */

    .InputfieldText,
    .InputfieldSelect,
    .InputfieldTextarea,
    .InputfieldEmail,
    .InputfieldUpload {

        .InputfieldHeader {
            position: absolute;
            top: 0;
            left: 0;
            transition: all 0.3s 0s;
            opacity: 0;
            font-size: 0.75em;
            color: $label-text-color;
            z-index: 1;

            .toggle-icon {
                display: none;
            }
        }

        .InputfieldHeader.show {
            opacity: 1;

            &.on {
            color: var(--t-content)
            }
        }
    }


    .InputfieldSelect{

        .InputfieldHeader {
            opacity: 1;
        }
    }

    .InputfieldTextarea {
        .InputfieldHeader {
            // bottom: -$input-padding*0.25;
        }
    }


    /* -------------------------------------*/
    /* Hidden field */

    .InputfieldHidden {
        display: none;
    }

    /* -------------------------------------*/
    /* Control group */

    //checkbox and radio groups
    .InputfieldCheckbox,
    .InputfieldCheckboxes,
    .InputfieldRadios {
        display: block;
        position: relative;
        padding-bottom: 0;
        margin: $input-padding 0 $input-padding*2;
        font-size: $form-font-size;

        // Remove * that gets added to after on required checkbox... Yeah, I know, sorry!
        &.InputfieldStateRequired > .InputfieldContent > label > span.pw-no-select::after {
            content: "" !important;
            margin-left: 0 !important;
        }

        // Label that contains the input (I know!!!!)
        label {
            position: relative;
            margin: 0;
            cursor: pointer;
            display: block;
        }

        // Info label only (I know!!!!)
        .InputfieldHeader {
            position: relative; //override base styling
            font-size: 1em; //override base styling
            top: 0; //override base styling
            left: 0; //override base styling
            font-weight: normal;
            font-weight: bold;
            margin-bottom: $input-padding;
        }

        // The actual input
        input {
            position: absolute;
            z-index: -1;
            opacity: 0;

            // Checked
            &:checked ~ .pw-no-select {

                &:before {
                    background: $radio-checkbox-bg-color--highlight;
                }

                &:after {
                    display: block;
                }
            }

            // Disbled
            &:disabled ~ .pw-no-select {

                &:before {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }

            // Hover
            &:hover ~ .pw-no-select:before,
            &:focus ~ .pw-no-select:before {
                border-color: $radio-checkbox-border-color--highlight;
            }
        }

        

        // &:hover input:not([disabled]):checked ~ .pw-no-select:before,
        // input:checked:focus ~ .pw-no-select:before {
        //     background: $brand-color1;
        // }
    }

    // label containing checkbox / radio button
    .pw-no-select {
        cursor: pointer;

        // The checkbox box / radio circle
        &:before {
            content: '';
            position: relative;
            display: inline-block;
            vertical-align: middle;
            height: $radio-checkbox-size;
            width: $radio-checkbox-size;
            background-color: $radio-checkbox-bg-color;
            border: 1px solid $radio-checkbox-border-color;
            margin-right: math.div($input-padding, 2);
            transition: all 0.2s 0s;

            .InputfieldRadios & {
                border-radius: 50%;
            }
        }

        // The tick that appears when selected
        &:after {
            content: "";
            position: absolute;
            top: math.div($radio-checkbox-size, 2);
            left: math.div($radio-checkbox-size, 2);
            width: math.div($radio-checkbox-size, 4);
            height: math.div($radio-checkbox-size, 2);
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: translateX(-(math.div($radio-checkbox-size, 7.5))) translateY(-(math.div($radio-checkbox-size, 4))) rotate(45deg);
            transform-origin: center center;
            border-color: $radio-checkbox-tick-color;
            display: none;
        }
        
    }


    /* -------------------------------------*/
    /* Select */

    $select-arrow-size: 8px;

    .InputfieldSelect {
        position: relative;
        display: inline-block;
        // margin-bottom: 2em;
        width: 100%;

        .InputfieldContent {
            position: relative;
        }
    }

    .InputfieldSelect select {
        display: inline-block;
        cursor: pointer;
        outline: none;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        margin-bottom: 0;
        padding-right: $input-padding*2; // Chrome and safari seem to need twice as much padding here

        option {
            padding: $input-padding;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .InputfieldSelect select::-ms-expand {
        display: none;
    }

    .InputfieldSelect select:hover,
    .InputfieldSelect select:focus {
        outline: none;
    }

    .InputfieldSelect select:disabled {
        opacity: 0.5;
        pointer-events: none;
    }



    /* -------------------------------------*/
    /* Submit button */

    .InputfieldSubmit {
        border-top: 1px solid;
        padding-top: 2em !important;
        margin-top: 2em !important
    }

    /* -------------------------------------*/
    /* Error / Success */

    // This knocks a lot of things out of whack with position absolute so we just set this to 0
    .maxColHeightSpacer {
        height: 0 !important;
    }

    .error {
        color: $red !important;
    }

    .FormBuilderErrors {
        margin-bottom: $input-padding;
    }

    .success {
        background: $white;
        color:$grey;
        padding:2em;
        border: 1px solid $grey;
        font-size:1.25em;
    }

    .FormBuilder {
        .notes {
            background: mix($coral, $white, 25%);
            padding: 0.25rem 0.75rem 0.35rem 0.75rem;
            line-height: 1.25;
            color: $grey;
        }
    }

    /* -------------------------------------*/
    /* Display fix */

    body {
        /* the following line fixes a blink in chrome https://code.google.com/p/chromium/issues/detail?id=108025 */
        // -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0x)
    }


    // ——————————————————
    // Contact Form Only
    .FormBuilder-contact-form {

        .InputfieldText,
        .InputfieldSelect,
        .InputfieldTextarea,
        .InputfieldEmail,
        .InputfieldUpload {

            .InputfieldHeader {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
        }

        input[type=text],
        input[type=url],
        input[type=tel],
        input[type=number],
        input[type=color],
        input[type=email],
        input[type=password],
        .textbox,
        select,
        textarea { 
            padding: 1em;
        }
    } 
}

