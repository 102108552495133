@use 'sass:color';
@use '../abstracts' as *;

.basic-image {
    position: relative;
    display: flex;
    justify-content: center;

    &__picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;  // Use focus in cms for best result
    }
}