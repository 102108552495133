/* ======================================== */
/* Block Standard */
/* ======================================== */
@use "abstracts" as *;

.block-standard {
    display: grid;
    grid-template-columns: minmax(50%, 850px);
    // min-height: 20vmin;

    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;

    // Modifiers
    &--sidebar { 
        
        @include breakpoint(md) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;
            padding-right: 0;
        }
    }
    
    &--align-x-start { 
        justify-content: flex-start; 
    }

    &--align-x-center { 
        justify-content: center; 
    }

    &--align-x-end { 
        justify-content: flex-end; 
    }

    // Elements
    &__content {
        display: flex;
        flex-direction: column;
        color: var(--t-content);
    }

    &__aside {
        @include breakpoint(md) {
            display: grid;
            align-items: flex-start;
            justify-content: flex-end;
        }
    }

    &__sticky-content {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        background-color: $light-grey;
        color: $black;

        @include breakpoint(md) {
            position: sticky;
            top: var(--sticky-nav-height, 140px);
            width: clamp(350px, 25vw, 500px);
        }

        .link-download {
            // color: $black;
        }
    }
}

