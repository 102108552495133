@use '../abstracts/' as *;

.block_table {

    @extend %content-horizontal-spacing;
    @extend %content-vertical-spacing;
}

.fleet-table {
    width: 100%;
    border-spacing: 0;
    text-align: center;


    &__head, thead {

        text-transform: uppercase;
    }
    
    &__body, tbody {
        color: black;
        background-color: white;

        tr:nth-child(even) {
            background-color: rgba(0, 86, 112, 0.1);
        }
    }

    th, td {
        min-width: 150px;
        // min-width: 300px;
        padding: 1rem 0.25rem;
    }

    th {
        background-color: #005670;
        color: white;
        position: sticky;
        top: 0;
    }
}

.table-scroller {
    overflow-x: scroll;
    overflow-y: visible;
    padding-bottom: 5px;
    width: auto;

    // Hide scrollbar
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera */
    }
}

.stick {
    background-color: #005670;
    color: white;
    position: sticky;
    top: 0;
}