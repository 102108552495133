/* ======================================== */
/* rich-text */
/* ======================================== */
@use '../abstracts/variables/color-site' as *;

.rich-text {
    h2,h3,h4,h5,h6 {
		text-transform: none;
	}

	h1 {
		margin-bottom: 0.5em;
	}

	p + h2,
	p + h3,
	p + h4,
	p + blockquote {
		margin-top: 1em;
	}

	ul,ol {
		margin: 0 0 2em 0;
		padding: 0 0 0 20px;
		font-size: 1rem;
		text-align: left;

		li {
			margin-bottom: 0.5em;
			line-height: 140%;
			font-weight: 400;
            list-style-image: url(/site/templates/images/svg/buttons/chevron-right.svg);
            padding-inline-start: 0.5rem;
		}
	}

	ul {
		list-style-type: disc;
	}

	ul.list-simple,
	ol.list-simple {
		list-style-type: none;
		padding: 0;
	}

	img {
		max-width: 100%;
	}

	iframe {
		margin-bottom: 2em;
	}

	figcaption {
		margin: 1em 0 2em 0;
		text-align: center;
	}

	a {
		// text-decoration: underline;
		transition: all 0.2s ease-in-out;
		font-weight: 400;
	}

	a.btn {
		text-decoration: none !important;
		margin-top: 2em;
		font-weight: 600;
	}

	p > .btn {
		margin-top: initial;
	}

	.cta-strip {
		padding-top: 1em; // override base

		.btn {
			margin-top: 0; // override
		}
	}

	blockquote {
        color: $ocean-depths;
        background-color: $light-grey;
		font-size: 1.1rem;
		padding: 2em;
        margin: 1rem 0;

        p:last-child {
            margin: 0;
        }
    }

}

.highlight{
    &--coral {
        color: $coral;
        
        a:hover {
            color: $black;
        }
    }
    &--ocean {
        color: $ocean-depths;
        
        a:hover {
            color: $dark-ocean;
        }
    }
    &--blue {
        color: $snorkel-blue;
        
        a:hover {
            color: $dark-blue;
        }
    }

}

