/* ======================================== */
/* navigation */
/* ======================================== */
@use '../abstracts' as *;

.sticky-nav-margin {
    margin-top: var(--sticky-nav-height);
}

// ---------------
// Hide Scroll bar
.scrollbar-is-hidden {
    overflow: hidden;
}

// Change breakpoints here in one place
$mobile-view: md-max;
$desktop-view: md;

// Primary nav
$dropdown-indicator-size: 2em;
.site-nav-primary {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;

    // mobile-view navigation
    @include breakpoint($mobile-view) {
        position: fixed;
        top: var(--sticky-nav-height);
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--t-bg);

        display: block;
        opacity: 0;
        visibility: hidden;

        transform: translateY(100%);
        transition: transform 0.6s ease-in-out ;

        li {
            opacity: 0;
            transform: translateY(50%);
            transition: all 0.33s 0.6s ease-in ;
        }
        
        &.open {
            display: block;
            opacity: 1;
            visibility: visible;
            transform: translateY(0%);
            overflow-y: scroll;

            li {
                opacity: 1;
                transform: translateY(0%);
            }
        }

        .level-1 > li {

            a {
                width: fit-content;
            }
        }
    }

    // Desktop Navigation
    @include breakpoint($desktop-view) {
        position: relative;
        padding: 0;
    }

    @include breakpoint(mx) {
        margin: 0;
    }

    > ul {

        > li {
            position: relative;

            a {
                text-decoration: none;
            }

            @include breakpoint($mobile-view) {
                // margin-right: 2em;
                padding: 1rem 0;

                &:last-child {
                    // margin-right: 0;
                }

                a {
                    padding: 0;
                }

                // Devices that support touch
                @media(hover: none) and (pointer: coarse) {

                    // First level only
                    > .dropdown-toggle {
                        margin-left: 0.5em;
                    }
                }
            }
        }
    }

    ul {

        @include breakpoint($mobile-view) {
            width: 100%;
        }

        @include breakpoint($desktop-view) {
            display: flex;
            flex-direction: row;
        }

        > li {

            @include breakpoint($mobile-view) {
                > ul {
                    margin: 0.5rem 0;
                    display: none; // Hide 2nd level and down and controlled via js
                    background: var(--t-standout-bg);
                    padding: 1rem 0 1rem 1rem 1rem;
                    border-radius: 0.5rem;

                    &.is-active {
                        display: block;
                    }

                    li {
                        margin: 0;

                        > ul {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        a {
                            font-size: 1em;
                            font-family: $font-stack;
                            margin: 0.5em 0;
                            flex-grow: 0;
                            max-width: calc(100% - 4em);
                        }

                        .dropdown-toggle {
                            // background: rgba($mid-blue, 0.2); // Always lightly darker than background
                            background: var(--t-link);
                            margin-right: 1em;
                        }
                    }
                }
            }

            @include breakpoint($desktop-view) {

                // Devices that support hover
                @media(hover: hover) and (pointer: fine) {
                    > a {
                        .dropdown-indicator {
                            transform: translateY(-25%) rotate(45deg);
                            transform-origin: center center;
                        }
                    }
                    > ul {

                        li {

                            &:first-child {
                                // padding-top: 1em;
                            }

                            &:last-child {
                                border: none;
                                // padding-bottom: 1em;
                            }

                            > a {
                                .dropdown-indicator {
                                    transform: rotate(-45deg);
                                    transform-origin: center center;
                                }
                            }
                        }
                    }

                    &:hover {
                        > ul {
                            transform: translateY(0) translateX(-50%);
                            opacity: 1;
                            visibility: visible;
                            transition: opacity 0.3s 0s, transform 0.3s 0s;
                        }
                    }
                }

                // Devices that support touch
                @media(hover: none) and (pointer: coarse) {
                    
                    padding: 1rem 0;

                    > ul {
                        &.is-active {
                            transform: translateY(0) translateX(-50%);
                            opacity: 1;
                            visibility: visible;
                            transition: opacity 0.3s 0s, transform 0.3s 0s;
                        }

                        li {
                            justify-content: space-between;

                            &:first-child {
                                padding-top: 2em;
                            }

                            &:last-child {
                                border: none;
                                padding-bottom: 2em;
                            }

                            a {
                                justify-content: flex-start;
                                width: auto;
                                text-align: left;
                                min-height: $dropdown-indicator-size;
                            }
                        }

                        .dropdown-toggle {
                            transform: rotate(-90deg);

                            &.is-active {
                                transform: rotate(90deg);
                            }
                        }
                    }
                }

                // > 1st level
                > ul {
                    position: absolute;
                    z-index: 1;
                    left: 40%;
                    top: calc(100% + 1em);
                    width: 28ch;
                    background: var(--t-bg);
                    // border-radius: 1rem;
                    box-shadow: 0 1.5rem 4rem 0 rgba(0, 0, 0, 0.3);
                    display: block;
                    // font-size: 0.75rem;
                    font-size: 1rem;
                    transform: translateY(1rem) translateX(-50%);
                    overflow: visible;

                    // Visually hidden for reveal on hover
                    opacity: 0;
                    visibility: hidden;
                    transition: visibility 0s 0.6s, opacity 0.6s 0s, transform 0.6s 0s;

                    @include breakpoint($desktop-view) {
                        width: 30ch;
                    }

                    @include breakpoint(lg) {
                        width: 30ch;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        bottom: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        display: block;
                        width: 0;
                        border-style: solid;
                        border-width: 0.5rem;
                        border-color: transparent transparent var(--t-link) transparent;
                    }

                    li {
                        margin: 0;
                        position: relative;
                        // border-bottom: 1px solid;
                        // border-color: var(--t-link);

                        // Devices that support hover
                        @media(hover: hover) and (pointer: fine) {
                            &:hover {
                                > ul {
                                    transform: translateY(0) translateX(-1.5rem);
                                    opacity: 1;
                                    visibility: visible;
                                    transition: opacity 0.3s 0s, transform 0.3s 0s;
                                }
                            }

                            a {
                                padding: 1em; // padding goes on a hitarea here
                            }
                        }

                        // Devices that support touch
                        @media(hover: none) and (pointer: coarse) {

                            padding: 1em; // padding goes on li here

                            > ul {
                                &.is-active {
                                    transform: translateY(0) translateX(0);
                                    opacity: 1;
                                    visibility: visible;
                                    transition: opacity 0.3s 0s, transform 0.3s 0s;
                                }
                            }
                        }

                        // > 2nd level and down
                        > ul {
                            // Also inherits styles from further up
                            left: 100%;
                            top: 0;
                            z-index: 2;
                            transform: translateY(0) translateX(0);

                            &:before {
                                display: none;
                            }
                        }

                        a {
                            font-size: 1rem;
                            border: none;
                            font-family: $font-stack;
                            font-weight: normal;

                            @include breakpoint($desktop-view) {
                                font-weight: bold;
                            }
                            
                        }
                    }

                    > li {
                        &:not(:first-child) {
                            > ul {
                                margin-top: -1em;
                            }
                        }
                    }
                }
            }
        }
    }

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;

        @include breakpoint($mobile-view) {
            flex-wrap: wrap;
        }
        
        @include breakpoint(md) {
            flex-basis: auto;
            flex-grow: 1;
        }

        a {
            font-family: $font-stack-heading;
            font-weight: 900;
            text-transform: capitalize;

            @include breakpoint($mobile-view) {
                display: inline-block;
                margin: 0.25em 0;
                font-size: 1.125em;
                // flex-grow: 1; 
            }

            @include breakpoint(md) {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 100%;
                padding: 0.5em;
                text-align: center;
                transition: color 0.3s 0s;

                // &:hover,
                // &:focus {
                //     color: var(--t-link--action);
                // }
            }

            @include breakpoint(lg) {
                font-size: 1em;
            }
        }

        .dropdown-indicator {
            @include breakpoint($mobile-view) {
                display: none;
            }
            
            @include breakpoint(md) {
                display: block;
                margin: 0 0 0 0.5em;
                padding: 0;
                width: 0.5em;
                height: 0.5em;
                border: 1px solid;
                border-color: transparent currentColor currentColor transparent;

                // Devices that don't support hover but support touch
                @media(hover: none) and (pointer: coarse) {
                    display: none;
                }
            }
        }

        .dropdown-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: $dropdown-indicator-size;
            height: $dropdown-indicator-size;
            border: none;
            border-radius: 1.5em;
            margin: 0;
            padding: 0;
            flex-shrink: 0;
            transition: transform 0.2s 0s ease-out;

            @include breakpoint(md) {
                // Devices that support hover
                @media(hover: hover) and (pointer: fine) {
                    display: none;
                }
            }

            &__chevron {
                display: block;
                margin: 0;
                padding: 0;
                width: 0.5em;
                height: 0.5em;
                border: 1px solid;
                border-color: transparent currentColor currentColor transparent;
                transform: translateY(-25%) rotate(45deg);
                transform-origin: center center;
            }

            &:hover,
            &:focus,
            &.is-active{

                .dropdown-toggle__chevron {
                    border-color: transparent $white $white transparent;
                }
            }

            &:hover {
                background: var(--t-link--action);
            }

            &:focus {
                background: var(--t-link--action);
            }

            &.is-active {
                background: var(--t-link--action);
                transform: rotate(180deg);
                transition-delay: 0s;

                &:focus {
                    background: var(--t-link--action);
                }
            }
        }
    }
}


/* ---------- Colours only ---------- */

// Desktop only
@include breakpoint($desktop-view) {
        
    // Before navigation becomes sticky
    .level-0 {
        > li {
            > a {
                color: $white;
                
                &:hover,
                &:focus {
                    color: var(--t-link--action);
                }
            }
        }
    }

    // When navigation is sticky
    .nav-is-sticky {
            
        .level-0 {
            > li {
                > a {
                    color: var(--t-link);
                    
                    &:hover,
                    &:focus {
                        color: var(--t-link--action);
                    }
                }
            }
        }
    }
}

