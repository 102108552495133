.site-notice {
    background: var(--t-bg);
    color: var(--t-content);

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    min-height: 50px;

    &__link {
        text-decoration: none;
        padding: 1rem 2rem;
        background: var(--t-btn-bg);
        border: solid 1px;
        border-color: var(--t-btn-border);
        color: var(--t-btn-content);

        &:hover, &:focus {
            background: var(--t-bg--action);
            border-color: var(--t-btn-border--action);
            color: var(--t-content--action);
        }
    }

    // Position Modifiers
    &--default {
        // Code
    }

    &--top {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
    }

    &--bottom {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.display-none {
    display: none;
}