/* ======================================== */
/* Block Map */
/* ======================================== */

@use "abstracts" as * ;

.gmap-mixed {
    height: calc(100vh - var(--sticky-nav-height)) !important;
}
.gmap-mixed {
    width: 100%;
    position: relative;
}

.tab_name {
    padding:15px;
    background:white;
    font-family:helvetica;
}

.w3-bar .w3-button {
    white-space: normal;
    font-family:helvetica;
    cursor:pointer;
}
.w3-bar .w3-bar-item {
    padding: 8px 16px;
    float: left;
    width: auto;
    border: none;
    display: block;
    outline: 0;
}
.w3-dark-grey, .w3-hover-dark-grey:hover, .w3-dark-gray, .w3-hover-dark-gray:hover {
    color: #fff !important;
    background-color: #616161 !important;
}

.w3-container::after, .w3-container::before, .w3-panel::after, .w3-panel::before, .w3-row::after, .w3-row::before, .w3-row-padding::after, .w3-row-padding::before, .w3-cell-row::before, .w3-cell-row::after, .w3-clear::after, .w3-clear::before, .w3-bar::before, .w3-bar::after {
    content: "";
    display: table;
    clear: both;
}
element {
}
.w3-light-grey, .w3-hover-light-grey:hover, .w3-light-gray, .w3-hover-light-gray:hover {
    color: #000 !important;
    background-color: #f1f1f1 !important;
}
.w3-border-bottom {
    border-bottom: 1px solid #ccc !important;
}
.w3-border {
    border: 1px solid #ccc !important;
}
*, ::before, ::after {
    box-sizing: inherit;
}
.w3-white, .w3-hover-white:hover {
    color: #000 !important;
}

.w3-container::after, .w3-container::before, .w3-panel::after, .w3-panel::before, .w3-row::after, .w3-row::before, .w3-row-padding::after, .w3-row-padding::before, .w3-cell-row::before, .w3-cell-row::after, .w3-clear::after, .w3-clear::before, .w3-bar::before, .w3-bar::after {
    content: "";
    display: table;
    clear: both;
}

.block-map {
    display:flex;
    flex-direction: column-reverse;

    @include breakpoint(sm) {
        flex-direction: row;
    }

    &__sticky {
        position: sticky;
        top: var(--sticky-nav-height);
    }

    &__entry {
        flex-basis: 35%;
    }
    
    &__map {
        flex-basis: 65%;
    }

    &__card {
        border-bottom:1px solid grey;
        padding:40px;

        a {
            text-decoration: none;
        }
    }
}

.map_container {
    width:100%;
    height:100%;
}

// Background to map

.gm-style {
    background-color: #6690b3;
}