/* ======================================== */
/* Block Cover */
/* ======================================== */

@use '../abstracts' as *;

.cover {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-areas: "main";
    background-color: var(--t-bg);

    &__media {
        z-index: 10;
        overflow: hidden;
        position: absolute;
        inset: 0;
    }

    &__content {
        @extend %content-horizontal-spacing;
        @extend %content-vertical-spacing;

        grid-area: main;
        z-index: 20;
        color: $white;
        color: var(--t-content);
        display: grid;
        grid-template-rows: 1fr auto;
        gap: 4rem;
        padding-top: 110px;


        /* --- Modifiers --- */        
        &.align-x-start { 
            justify-items: flex-start; 
        }
        &.align-x-center { 
            justify-items: center; 
            text-align: center;
        }
        &.align-x-end { 
            justify-items: flex-end;

            .intro--indented {
       
                .primary-heading {
                    text-align: right;
                    @include breakpoint(sm-max) {
                        text-align: left;
                    }
                }
            }
        }
    }

    &__picture {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.intro {
    max-width: 70ch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.align-y-start { justify-content: flex-start; }
    &.align-y-center { justify-content: center; }
    &.align-y-end { justify-content: flex-end; }
    &.align-y-between { justify-content: space-between; }

    &__top {
       
        .primary-heading {
            font-size: 4rem;
        }
    }

    &__bottom {
        .primary-heading {
           font-size: 1.75rem;
        }
    }

    &__text {
        font-size: 1.3rem;
        text-shadow: 0 0 30px rgb(0 0 0 / 60%);
        font-weight: 500;
    }

    &--indented {
        max-width: 55ch;
        @include breakpoint(sm-max) {
            max-width: 100%;
        }

        .intro__text {
            margin-left: 5em;
            @include breakpoint(sm-max) {
                margin-left: 0;
            }
        }
    }
}

/* --- Cards --- */
.card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-self: flex-end;

    &.align-x-start { justify-content: flex-start; }
    &.align-x-center { justify-content: center; }
    &.align-x-end { justify-content: flex-end; }
}

.card {
    flex-basis: calc(20% -2rem);
    min-width: 240px;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--t-standout-bg);
    font-size: 1rem;
    text-align: left;

    @include breakpoint(xs) {
        min-width: 300px;
    }

    &__heading {
        font-size: 1.5rem;
        font-weight: 800;
        color: var(--t-standout-content);
        margin-bottom: 1.25rem;
    }

    &__text {
        color: var(--t-standout-content);
        margin-bottom: 0.25rem;
    }

    &__link {
        margin-top: auto;
        width: fit-content;
        display: inline-block;
        padding: 1rem 2rem;
        font-weight: 900;
        text-decoration: none;
        text-transform: uppercase;
        border: 2px solid;
        border-radius: 3px;

        color: var(--t-standout-bg);
        border-color: var(--t-standout-content);
        background: var(--t-standout-content);

        transition: all 0.35s ease-in-out;

        &:hover {
            color: var(--t-standout-content);
            background: transparent;
        }
    }
}

/* --- Clip Shapes --- */
.clip {
    display: block;
    height: 100%;
    
    &--chevron-right {
        &::before {
            background: var(--clip-color);
        }

        @include breakpoint(lg) {
            // clip-path: polygon(0 0,0 100%,30% 100%,100% 50%,30% 0);
            clip-path: polygon(0 0,0 100%,40% 100%,100% 50%,40% 0);
            width: 1200px; // Keep's img width equal to clip

            &:before {
                content: "";
                display: block;
                width: 20vh;
                height: 20vh;
                clip-path: polygon(0 0, 0% 100%, 100% 50%);
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                mix-blend-mode: multiply;
                filter: opacity(0.8);
            }
        }
    }

    // Change all shapes to overlay on mobile 
    &--overlay {
        &::after {
            content: "";
            position: absolute;
            inset: 0;
            display: block;
            background: var(--clip-color);
            filter: opacity(.8);
            mix-blend-mode: multiply;
        }

        @include breakpoint(lg) {
            &:after {
                display: none;
            }
        }
    }
}