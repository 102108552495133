/* ======================================== */
/* typography */
/* ======================================== */
@use 'abstracts' as *;

html {
    font-size: 1rem;

    @media only screen and (min-width: 2000px) {
        font-size: 1.25rem;
    }

    @media only screen and (min-width: 3000px) {
        font-size: 1.5rem;
    }
}


body {
	font-family: $font-stack;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6,
.alpha, .beta, .gamma, .delta, .epsilon, .zeta {
 	margin: 0 0 1em 0;
 	font-weight: bold;
	font-family: $font-stack-heading;
}

h1, .alpha {
	font-size: 40px;
	line-height: 1em;
	margin-bottom: 0.25em;
	// text-transform: uppercase;

	// @include breakpoint(md-max) {
	// 	font-size: 32px;
    // }

	// @include breakpoint(mx) {
	// 	font-size: 42px;
    // }

}

h2, .beta {
	font-size: 32px;
	line-height: 1em;
	margin-bottom: 0.75em;
	font-weight: bold;

	// @include breakpoint(sm-max) {
	// 	font-size: 24px;
    // }
}
h3, .gamma {
	font-size: 24px;
	line-height: 1.25em;
	font-weight: bold;

	// @include breakpoint(sm-max) {
	// 	font-size: 20px;
    // }
}

h4, .delta {
	font-size: 20px;
	line-height: 1.25em;
	font-weight: bold;

	// @include breakpoint(sm-max) {
	// 	font-size: 18px;
    // }
}

h5, .epsilon {
	font-size: 18px;
	line-height: 1.25em;
	font-weight: bold;
}

h6, .zeta {
	font-size: 16px;
	line-height: 1.25em;
	font-weight: bold;
}

.p {
	font-size: 16px;
	line-height: 1.4em;
	
	// @include breakpoint(sm-max) {
	// 	line-height: 1.25em;
    // }
}

p {
	font-weight: 300;
}

p, li, label {
	margin: 0 0 1em 0;
	line-height: 1.4em;
}

a {
    color: var(--t-link);
        &:hover,
        &:focus {
            color:var(--t-link--action);
        
    }
}


/* -----------------------------*/
/* STYLE */

.text-uppercase {
	text-transform: uppercase;
}

.text-nocase {
	text-transform: none;
}

/* -----------------------------*/
/* SIZE */

// Rich Text Edior
.text-tiny {
	font-size: 0.5em;
	line-height: 1em;
	display: inline-block;
}

.text-small {
	font-size: 0.9em;
	line-height: 1.125em;
	display: inline-block;
}

// CMS size select
.text-normal {
    font-size: 1rem !important;
}

.text-medium {
    font-size: 1.75rem !important;
}

.text-large {
    font-size: 2rem !important;
}

.text-xlarge {
    font-size: 3rem !important;
}

.text-huge {
    font-size: 4rem !important;
}

/* -----------------------------*/
/* WEIGHT */

.text-light {
	font-weight: 300;
}

.text-regular {
	font-weight: normal;
}

.text-bold {
	font-weight: bold;
}


/* -----------------------------*/
/* TEXT SHADOW */
.text-shadow {
    text-shadow: 0 0 15px rgb(0, 0, 0, 50%);
}