@use 'abstracts' as *;

.block-cta-image {
    width: 100%;
    display: block;
    color: var(--t-content);

    &__grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        color: var(--t-content);

        @include breakpoint(md) {
            grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
        }

        @include breakpoint(mx) {
            grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
        }

        // If there is only one item
        &--single {
            grid-template-columns: 1fr;
            @include breakpoint(md){
                display: block;

                > .box-image {
                    max-width: 50%;
                    margin: 0 auto;
                }
            }
        }
    }
}