/* ======================================== */
/* breadcrumb */
/* ======================================== */

@use 'sass:color';
@use '../abstracts' as *;

$breadcrumb-arrow-size: 0.5em;
$breadcrumb-link-spacing: 0.5rem;


.breadcrumb {
    display: flex;
    // background: var(--t-standout-bg);
    // background: #{color.scale($ocean-depths, $lightness: 20%)};

    &__list {
        margin: 0;
        padding-top: 1.125rem;
        padding-bottom: 1.125rem;
        padding-right: 1.125rem;
        background: var(--t-bg);
        filter: brightness(1.25);
        
        @extend %content-left-spacing;
    }
        
    &__list-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        vertical-align: middle;
        display: none;

        &:last-child {
            display: inline-block;
        }

        @include breakpoint(sm) {
            display: inline-block;
        }

    }
    
    &__link {
        text-decoration: none;
        text-transform: capitalize;
        color: var(--t-link);
        padding: 0 $breadcrumb-link-spacing;
        transition: all 0.25s ease-in-out;

        &::after {
            content:"";
            position: relative;
            left: $breadcrumb-link-spacing;
            display: inline-block;
            vertical-align: middle;
            width: $breadcrumb-arrow-size;
            height: $breadcrumb-arrow-size;
            border: solid;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg);
            border-color: var(--t-link) var(--t-link) transparent transparent;
            margin-right: 0.5em;
            margin-top: -0.2em;
            transition: all 0.25s ease-in-out;
        }

        &:hover {
            color: var(--t-link--action);
            
            &:after {
                border-color: var(--t-link--action) var(--t-link--action) transparent transparent;
            }
        }
    }
}

.breadcrumb-title {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;

    &__title {
        margin-bottom: 0;
        padding-left: 2.5rem;
        color: var(--t-content);
        font-weight: 900;
        text-transform: capitalize;
        font-size: 1rem;
    }

    @extend %content-right-spacing;
}

