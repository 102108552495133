// ----------------------------------------
// site color variables
// add custom site color variables here
// implement in theme.scss
// ----------------------------------------

@use 'sass:color';

// Assign your custom colours as root variables

// Examples only, remove and use your own
// shades
$white: #ffffff;
$black: #000000;

/* ----------------------------- */
/* BRAND COLORS */

$snorkel-blue: #004680;     // Snorkel Blue  blue colour
$ocean-depths: #005670;     // Ocean Depths,  teal colour
$dark-ocean: #001a22;       // Dark shade of Ocean Depth 
$dark-blue: #001526;        // Dark shade of Snorkel Blue
$coral: #FF6F61;            // Living Coral

// Supplementary Colours - use sparingly - alerts etc
$green:#00723B;     // First Tee
$red:#951528;       // Savvy Red


$light-grey: #EEEFF0;
$dark: #051421;             // lets just remove this
$grey: #4B4B4B;             // Grey ?? can probably remove this too.

$blue: $snorkel-blue;
$teal: $ocean-depths;