/* ======================================== */
/* theme */
// Colours only
/* ======================================== */

@use 'sass:color';
@use '../abstracts' as *;

// Update root variables from default white label to custom
:root {
    // DEFAULT
        // background
        --t-bg:                                                 #{$white};
            --t-bg--action:                                     #{color.scale($white, $lightness: -10%)};
        // Content
        --t-content:                                            #{$black};
            --t-content--action:                                #{color.scale($ocean-depths, $lightness: -20%)};
        --t-content-highlight:                                  #{$ocean-depths};

        // link
        --t-link:                                               #{$ocean-depths};
            --t-link--action:                                   #{$coral};
        // link button - link with icon
        --t-linkbutton:                                         #{$black};
        --t-linkbutton-icon-bg:                                 transparent;
        --t-linkbutton-icon-content:                            #{$black};
            --t-linkbutton--action:                             #{$ocean-depths};
            --t-linkbutton-icon-bg--action:                     transparent;
            --t-linkbutton-icon-content--action:                #{$ocean-depths};
        // button
        --t-btn-border:                                         #{$ocean-depths};
        --t-btn-bg:                                             #{$ocean-depths};
        --t-btn-content:                                        #{$white};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$ocean-depths};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$ocean-depths};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};
        // Icon
        --t-icon-bg:                                            transparent;
        --t-icon-content:                                       #{$black};

    // Clip
        --clip-color:                                           #{$white};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$ocean-depths};
            --t-standout-bg--action:                            #{color.scale($ocean-depths, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$white};
            --t-standout-content--action:                       #{color.scale($white, $lightness: -50%)};

}

  
// ========================================
// Re-define colour relationships per theme
// ========================================

// ----------------------------------------
// Imagery theme

.theme-bg-imagery {
    --bg-color: transparent;
}
.theme-imagery {
    // DEFAULT
        // background
        --t-bg:                                                 transparent;
            --t-bg--action:                                     transparent;
        // Content
        --t-content:                                            #{$white};
            --t-content--action:                                #{color.scale($white, $lightness: -20%)};
        --t-content-highlight:                                  #{$coral};
        // link
        --t-link:                                               #{$white};
            --t-link--action:                                   #{$coral};
        // link button - link with icon
        --t-linkbutton:                                         #{$white};
        --t-linkbutton-icon-bg:                                 transparent;
        --t-linkbutton-icon-content:                            #{$white};
            --t-linkbutton--action:                             #{$coral};
            --t-linkbutton-icon-bg--action:                     transparent;
            --t-linkbutton-icon-content--action:                #{$coral};
        // button
        --t-btn-border:                                         #{$white};
        --t-btn-bg:                                             #{$white};
        --t-btn-content:                                        #{$ocean-depths};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$white};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$white};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};
        // Clip
        --clip-color:                                           #{$white};
   
    // STANDOUT
        // background
        --t-standout-bg:                                        #{$light-grey};
        --t-standout-bg--action:                                #{color.scale($light-grey, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$ocean-depths};
            --t-standout-content--action:                       #{color.scale($ocean-depths, $lightness: -20%)};
}


// ----------------------------------------
// white theme

.theme-bg-white {
    --bg-color: #{$white};
}
.theme-white {
    // DEFAULT
        // background
        --t-bg:                                                 #{$white};
            --t-bg--action:                                     #{color.scale($ocean-depths, $lightness: -10%)};
        // Content
        --t-content:                                            #{$black};
            --t-content--action:                                #{color.scale($ocean-depths, $lightness: -20%)};
        --t-content-highlight:                                  #{$ocean-depths};
        // link
        --t-link:                                               #{$ocean-depths};
            --t-link--action:                                   #{$coral};
        // link button - link with icon
        --t-linkbutton:                                         #{$black};
        --t-linkbutton-icon-bg:                                 transparent;
        --t-linkbutton-icon-content:                            #{$black};
            --t-linkbutton--action:                             #{$ocean-depths};
            --t-linkbutton-icon-bg--action:                     transparent;
            --t-linkbutton-icon-content--action:                #{$ocean-depths};
        // button
        --t-btn-border:                                         #{$ocean-depths};
        --t-btn-bg:                                             #{$ocean-depths};
        --t-btn-content:                                        #{$white};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$ocean-depths};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$ocean-depths};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};
        // Clip
        --clip-color:                                           #{$white};
   
    // STANDOUT
        // background
        --t-standout-bg:                                        #{$light-grey};
        --t-standout-bg--action:                                #{color.scale($light-grey, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$ocean-depths};
            --t-standout-content--action:                       #{color.scale($ocean-depths, $lightness: -20%)};
            --t-standout-content--highlight:                    #{$black};
}
     

// ----------------------------------------
// light-grey theme

.theme-bg-grey {
    --bg-color: #{$light-grey};
}
.theme-grey {
    // DEFAULT
        // background
        --t-bg:                                                 #{$light-grey};
            --t-bg--action:                                     #{color.scale($ocean-depths, $lightness: -10%)};
        // Content
        --t-content:                                            #{$ocean-depths};
            --t-content--action:                                #{color.scale($ocean-depths, $lightness: -20%)};
        --t-content-highlight:                                  #{$ocean-depths};
        // link
        --t-link:                                               #{$ocean-depths};
            --t-link--action:                                   #{$coral};
        // link button - link with icon
        --t-linkbutton:                                         #{$ocean-depths};
        --t-linkbutton-icon-bg:                                 transparent;
        --t-linkbutton-icon-content:                            #{$ocean-depths};
            --t-linkbutton--action:                             #{$coral};
            --t-linkbutton-icon-bg--action:                     transparent;
            --t-linkbutton-icon-content--action:                #{$coral};
        // button
        --t-btn-border:                                         #{$ocean-depths};
        --t-btn-bg:                                             #{$ocean-depths};
        --t-btn-content:                                        #{$white};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$ocean-depths};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$ocean-depths};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};

        // Clip
        --clip-color:                                           #{$light-grey};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$white};
        --t-standout-bg--action:                                #{color.scale($white, $lightness: -20%)};
        // content
        --t-standout-content:                                   #{$ocean-depths};
            --t-standout-content--action:                       #{color.scale($ocean-depths, $lightness: -20%)};
}


// ----------------------------------------
// ocean theme

.theme-bg-ocean-depths {
    --bg-color: #{$ocean-depths};
}
.theme-ocean-depths {
    // DEFAULT
        // background
        --t-bg:                                                 #{$ocean-depths};
            --t-bg--action:                                     #{color.scale($ocean-depths, $lightness: -10%)};
        // Content
        --t-content:                                            #{$white};
            --t-content--action:                                #{color.scale($ocean-depths, $lightness: -20%)};
        --t-content-highlight:                                  #{$ocean-depths};
        // link
        --t-link:                                               #{$white};
            --t-link--action:                                   #{$coral};
        // link button - link with icon
        --t-linkbutton:                                         #{$white};
        --t-linkbutton-icon-bg:                                 transparent;
        --t-linkbutton-icon-content:                            #{$white};
            --t-linkbutton--action:                             #{$coral};
            --t-linkbutton-icon-bg--action:                     transparent;
            --t-linkbutton-icon-content--action:                #{$coral};
        // button
        --t-btn-border:                                         #{$white};
        --t-btn-bg:                                             #{$white};
        --t-btn-content:                                        #{$ocean-depths};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$white};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$white};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};

        // Clip
        --clip-color:                                           #{$ocean-depths};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$white};
        --t-standout-bg--action:                                #{color.scale($white, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$ocean-depths};
            --t-standout-content--action:                       #{color.scale($ocean-depths, $lightness: -20%)};
            --t-standout-content--highlight:                    #{$white};
}


// ----------------------------------------
// snorkel-blue theme

.theme-bg-snorkel-blue {
    --bg-color: #{snorkel-blue};
}
.theme-snorkel-blue {
    // DEFAULT
        // background
        --t-bg:                                                 #{$snorkel-blue};
            --t-bg--action:                                     #{color.scale($snorkel-blue, $lightness: -10%)};
        // Content
        --t-content:                                            #{$white};
            --t-content--action:                                #{color.scale($snorkel-blue, $lightness: 50%)};
        --t-content-highlight:                                  #{$ocean-depths};
        // link
        --t-link:                                               #{$white};
            --t-link--action:                                   #{$coral};
        // link button - link with icon
        --t-linkbutton:                                         #{$white};
        --t-linkbutton-icon-bg:                                 transparent;
        --t-linkbutton-icon-content:                            #{$white};
            --t-linkbutton--action:                             #{$coral};
            --t-linkbutton-icon-bg--action:                     transparent;
            --t-linkbutton-icon-content--action:                #{$coral};
        // button
        --t-btn-border:                                         #{$white};
        --t-btn-bg:                                             #{$white};
        --t-btn-content:                                        #{$snorkel-blue};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$white};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$white};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$white};
        --t-standout-bg--action:                                #{color.scale($white, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$snorkel-blue};
            --t-standout-content--action:                       #{color.scale($snorkel-blue, $lightness: -20%)};
            --t-standout-content--highlight:                    #{$white};

    // Hero
        // background
        --t-hero-bg:                                            #{$dark-blue};
            --t-hero-bg--action:                                #{color.scale($snorkel-blue, $lightness: -10%)};
            --t-hero-bg--clip:                                  #{$snorkel-blue};

        // Content
        --t-hero-content:                                       #{$white};
            --t-hero-content--action:                           #{color.scale($snorkel-blue, $lightness: 50%)};
        // link
        --t-hero-link:                                          #{color.scale($snorkel-blue, $lightness: 50%)};
            --t-hero-link--action:                              #{color.scale($snorkel-blue, $lightness: 0%)};

        // Clip
        --clip-color:                                           #{$snorkel-blue};

}


// ----------------------------------------
// dark-blue theme

.theme-bg-dark-blue {
    --bg-color: #{$dark-blue};
}
.theme-dark-blue {
    // DEFAULT
        // background
        --t-bg:                                                 #{$dark-blue};
            --t-bg--action:                                     #{color.scale($dark-blue, $lightness: -10%)};
        // Content
        --t-content:                                            #{$white};
            --t-content--action:                                #{color.scale($dark-blue, $lightness: 50%)};
        --t-content-highlight:                                  #{$dark-blue};
        // link
        --t-link:                                               #{$white};
            --t-link--action:                                   #{$coral};
        // link button - link with icon
        --t-linkbutton:                                         #{$white};
        --t-linkbutton-icon-bg:                                 transparent;
        --t-linkbutton-icon-content:                            #{$white};
            --t-linkbutton--action:                             #{$coral};
            --t-linkbutton-icon-bg--action:                     transparent;
            --t-linkbutton-icon-content--action:                #{$coral};
        // button
        --t-btn-border:                                         #{$white};
        --t-btn-bg:                                             #{$white};
        --t-btn-content:                                        #{$dark-blue};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$white};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$white};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};

        // Clip
        --clip-color:                                           #{$dark-blue};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$white};
        --t-standout-bg--action:                                #{color.scale($white, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$dark-blue};
            --t-standout-content--action:                       #{color.scale($dark-blue, $lightness: -20%)};
   
    // Hero
        // background
        --t-hero-bg:                                            #{$dark-blue};
            --t-hero-bg--action:                                #{color.scale($dark-blue, $lightness: -10%)};
            --t-hero-bg--clip:                                  #{$dark-blue};

        // Content
        --t-hero-content:                                       #{$white};
            --t-hero-content--action:                           #{color.scale($dark-blue, $lightness: 50%)};
        // link
        --t-hero-link:                                          #{color.scale($dark-blue, $lightness: 50%)};
            --t-hero-link--action:                              #{color.scale($dark-blue, $lightness: 0%)};

}


// ----------------------------------------
// dark-ocean theme

.theme-bg-dark-ocean {
    --bg-color: #{$dark-ocean};
}
.theme-dark-ocean {
    // DEFAULT
        // background
        --t-bg:                                                 #{$dark-ocean};
            --t-bg--action:                                     #{color.scale($dark-ocean, $lightness: -10%)};
        // Content
        --t-content:                                            #{$white};
            --t-content--action:                                #{color.scale($dark-ocean, $lightness: 50%)};
        --t-content-highlight:                                  #{$dark-ocean};
        // link
        --t-link:                                               #{color.scale($dark-ocean, $lightness: 50%)};
            --t-link--action:                                   #{color.scale($dark-ocean, $lightness: 0%)};
        // link button - link with icon
        --t-linkbutton:                                         #{$white};
        --t-linkbutton-icon-bg:                                 transparent;
        --t-linkbutton-icon-content:                            #{$white};
            --t-linkbutton--action:                             #{$coral};
            --t-linkbutton-icon-bg--action:                     #{$white};
            --t-linkbutton-icon-content--action:                #{$coral};
        // button
        --t-btn-border:                                         #{$white};
        --t-btn-bg:                                             #{$white};
        --t-btn-content:                                        #{$dark-ocean};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$white};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$white};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};

        // Clip
        --clip-color:                                           #{$dark-ocean};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$white};
        --t-standout-bg--action:                                #{color.scale($white, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$dark-ocean};
            --t-standout-content--action:                       #{color.scale($dark-ocean, $lightness: -20%)};
            --t-standout-content--highlight:                    #{$black};
        // link
        --t-standout-link:                                      #{$dark-ocean};
            --t-standout-link--action:                          #{color.scale($dark-ocean, $lightness: -20%)};

}

// ----------------------------------------
// coral theme

.theme-bg-coral {
    --bg-color: #{$coral};
}
.theme-coral {
    // DEFAULT
        // background
        --t-bg:                                                 #{$coral};
            --t-bg--action:                                     #{color.scale($dark, $lightness: -10%)};
        // Content
        --t-content:                                            #{$white};
            --t-content--action:                                #{color.scale($dark, $lightness: 50%)};
        --t-content-highlight:                                  #{$ocean-depths};
        // link
        --t-link:                                               #{color.scale($dark, $lightness: 50%)};
            --t-link--action:                                   #{color.scale($dark, $lightness: 0%)};
        // link button - link with icon
        --t-linkbutton:                                         #{$white};
        --t-linkbutton-icon-bg:                                 transparent;
        --t-linkbutton-icon-content:                            #{$white};
            --t-linkbutton--action:                             #{$dark-blue};
            --t-linkbutton-icon-bg--action:                     transparent;
            --t-linkbutton-icon-content--action:                #{$dark-blue};
        // button
        --t-btn-border:                                         #{$white};
        --t-btn-bg:                                             #{$white};
        --t-btn-content:                                        #{$dark-blue};
            --t-btn-border--action:                             #{$dark-blue};
            --t-btn-bg--action:                                 #{$dark-blue};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$white};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$white};
            --t-btn-outline-border--action:                     #{$dark-blue};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$dark-blue};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$white};
        --t-standout-bg--action:                                #{color.scale($white, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$ocean-depths};
            --t-standout-content--action:                       #{color.scale($ocean-depths, $lightness: -20%)};

        // Clip
        --clip-color:                                           #{$coral};

}


// ========================================
// Theme Highlight
// ========================================
.highlight-white {
    // Default
        --t-content:                                            #{$white};
            --t-content--action:                                #{color.scale($white, $lightness: 50%)};
        --t-content-highlight:                                  #{$black};
        // link
        --t-link--action:                                       #{$white};
        // button
        --t-btn-border:                                         #{$white};
        --t-btn-bg:                                             #{$white};
        --t-btn-content:                                        #{$snorkel-blue};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$white};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$white};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};
            
        // Icon
        --t-icon-bg:                                            transparent;
        --t-icon-content:                                       #{$white};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$white};
        --t-standout-bg--action:                                #{color.scale($white, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$ocean-depths};
            --t-standout-content--action:                       #{color.scale($ocean-depths, $lightness: -20%)};
            --t-standout-content--highlight:                    #{$black};
}

.highlight-black {
    // Default
        // link
        --t-link--action:                                       #{$black};
        // button
        --t-btn-border:                                         #{$black};
        --t-btn-bg:                                             #{$black};
        --t-btn-content:                                        #{$white};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$black};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$black};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};
        // Icon
        --t-icon-bg:                                            transparent;
        --t-icon-content:                                       #{$black};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$black};
        --t-standout-bg--action:                                #{color.scale($black, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$white};
            --t-standout-content--action:                       #{color.scale($white, $lightness: -20%)};
}

.highlight-grey {
    // Default
        --t-content:                                            #{$grey};
            --t-content--action:                                #{color.scale($grey, $lightness: 50%)};
        --t-content-highlight:                                  #{$black};
        // link
        --t-link--action:                                       #{$grey};
        // button
        --t-btn-border:                                         #{$grey};
        --t-btn-bg:                                             #{$grey};
        --t-btn-content:                                        #{$snorkel-blue};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$grey};
        // button out-line
        --t-btn-outline-border:                                 #{$grey};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$grey};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};
            
        // Icon
        --t-icon-bg:                                            transparent;
        --t-icon-content:                                       #{$grey};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$white};
        --t-standout-bg--action:                                #{color.scale($white, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$ocean-depths};
            --t-standout-content--action:                       #{color.scale($ocean-depths, $lightness: -20%)};
            --t-standout-content--highlight:                    #{$black};
}

.highlight-snorkel-blue {
    // Default
        --t-content:                                            #{$snorkel-blue};
            --t-content--action:                                #{color.scale($snorkel-blue, $lightness: 50%)};
        // link
        --t-link--action:                                       #{$snorkel-blue};
        // button
        --t-btn-border:                                         #{$snorkel-blue};
        --t-btn-bg:                                             #{$snorkel-blue};
        --t-btn-content:                                        #{$white};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$snorkel-blue};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$snorkel-blue};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};
        // Icon
        --t-icon-bg:                                            transparent;
        --t-icon-content:                                       #{$snorkel-blue};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$snorkel-blue};
        --t-standout-bg--action:                                #{color.scale($snorkel-blue, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$white};
            --t-standout-content--action:                       #{color.scale($white, $lightness: -20%)};
}

.highlight-ocean-depths  {
    // Default
        --t-content:                                            #{$ocean-depths};
            --t-content--action:                                #{color.scale($ocean-depths, $lightness: 50%)};
        // link
        --t-link--action:                                       #{$ocean-depths};
        // button
        --t-btn-border:                                         #{$ocean-depths};
        --t-btn-bg:                                             #{$ocean-depths};
        --t-btn-content:                                        #{$white};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$ocean-depths};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$ocean-depths};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};
        // Icon
        --t-icon-bg:                                            transparent;
        --t-icon-content:                                       #{$ocean-depths};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$ocean-depths};
        --t-standout-bg--action:                                #{color.scale($ocean-depths, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$white};
            --t-standout-content--action:                       #{color.scale($white, $lightness: -20%)};
}

.highlight-dark-ocean {
    // Default
        --t-content:                                            #{$ocean-depths};
            --t-content--action:                                #{color.scale($ocean-depths, $lightness: 50%)};
        // link
        --t-link--action:                                       #{$ocean-depths};
        // button
        --t-btn-border:                                         #{$dark-ocean};
        --t-btn-bg:                                             #{$dark-ocean};
        --t-btn-content:                                        #{$white};
            --t-btn-border--action:                             #{$coral};
            --t-btn-bg--action:                                 #{$coral};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$dark-ocean};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$dark-ocean};
            --t-btn-outline-border--action:                     #{$coral};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$coral};
        // Icon
        --t-icon-bg:                                            transparent;
        --t-icon-content:                                       #{$dark-ocean};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$dark-ocean};
        --t-standout-bg--action:                                #{color.scale($dark-ocean, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$white};
            --t-standout-content--action:                       #{color.scale($white, $lightness: -20%)};
}

.highlight-coral {
    // Default
        --t-content:                                            #{$coral};
            --t-content--action:                                #{color.scale($coral, $lightness: 50%)};
        // link
        --t-link--action:                                       #{$coral};
        // button
        --t-btn-border:                                         #{$coral};
        --t-btn-bg:                                             #{$coral};
        --t-btn-content:                                        #{$white};
            --t-btn-border--action:                             #{$snorkel-blue};
            --t-btn-bg--action:                                 #{$snorkel-blue};
            --t-btn-content--action:                            #{$white};
        // button out-line
        --t-btn-outline-border:                                 #{$coral};
        --t-btn-outline-bg:                                     transparent;
        --t-btn-outline-content:                                #{$coral};
            --t-btn-outline-border--action:                     #{$snorkel-blue};
            --t-btn-outline-bg--action:                         transparent;
            --t-btn-outline-content--action:                    #{$snorkel-blue};
        // Icon
        --t-icon-bg:                                            transparent;
        --t-icon-content:                                       #{$coral};

    // STANDOUT
        // background
        --t-standout-bg:                                        #{$coral};
        --t-standout-bg--action:                                #{color.scale($coral, $lightness: -10%)};
        // content
        --t-standout-content:                                   #{$white};
            --t-standout-content--action:                       #{color.scale($white, $lightness: -20%)};
}


// ==========================================================
// Set background for each theme- and theme-bg- class.
// ==========================================================

/* Remove comment if you wish to add background color to each bloack with theme classs */
[class*='theme-'] {
    // background: var(--t-bg);
}

[class*='theme-bg-'] {
    background: var(--bg-color);
}

.text-highlight {
    color: $coral;
}
