@use '../abstracts/' as *;

.cta-special {
    display: flex;
    width: 100%;
    background-color: var(--t-bg);
    align-items: center;

    &__wrapper {
        flex-grow: 1;
        align-self: center;
        @extend %content-horizontal-spacing;
        @extend %content-vertical-spacing;
        background-color: var(--t-bg);
        z-index: 2;
        max-width: 50%;
        padding: 4vw;
        justify-content:center;
        margin: 3vw;

         @include breakpoint(lg) {
            margin: 7vw 5vw;
            max-width: 25%;
         }
    }

    &__heading {
        color: var(--t-content);
        font-size: 1.5rem;
    }

    &__content {
        color: var(--t-content);
        margin-bottom: 2em;

        p {
            margin-bottom: 0;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 7rem;
        min-height: 10rem;
        background-color: var(--t-bg);

        svg {
            fill: var(--t-content);
            width: 2rem;
            height: 2rem;
        }
    }

    &--left {
        justify-content: flex-start;
    }
    &--right {
        justify-content: right;
    }
    &--center {
        justify-content: flex-end;
    }
}
.cta-image {
  position: relative;
    display: flex;
    justify-content: center;
    text-decoration: none;
    overflow: hidden;       // Hides content on hover -> scale

    &--cover {
        height: 100%;
    }

    &__picture {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;  // Use focus in cms for best result
        transition: all 0.5s ease-in-out;
    }

}
