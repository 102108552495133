@use '../abstracts' as *;

.gallery-wrapper {
    @extend %content-horizontal-spacing;
    @extend %content-vertical-spacing;
}

.gallery {
    position: relative;
    gap: 2rem;

    &--grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }

    &__item {
        display: block;
        overflow: hidden;

        &:hover {
            
            .gallery__image {
                transform: scale(1.05);
                
            }
        }
    }

    &__figure {
        position: relative;
        width: 100%;
    }

    &__picture {
        display: block;
    }

    &__image {
        display: block;
        width: 100%;
        max-width: 100%;
        transition: all 0.5s ease-in-out;

        &--portrait {
            width: auto;
            max-height: 60vh; // Something sensible for viewing
        }
    }
}

// Show as: slider
// jsGallery - starts a slider

.jsGallery {
    position: relative;
    width: calc(100% + 2rem);
    margin-left: -1rem;

    .gallery__item {
        margin: 0 1rem;
    }
}

// Slider Controls, btn next/previous
.gallery-slider-controls {
    position: absolute;
    left: 0;
    top: calc(50% - 0.5rem);
    transform: translateY(-50%);
    width: 100%;

    &__btn {
        border: none;
        background: none;
        position: absolute;
        top: 0;
        cursor: pointer;

        &--prev {
            left: 0;
        }

        &--next {
            right: 0;
        }

        svg {
            width: 2rem;
            height: 2rem; 
        }

        .theme-icon-bg {
            transition: all 0.2s ease-in-out;
            fill: var(--t-link);
        }

        &:hover, &:focus {
            .theme-icon-bg {
                fill: var(--t-link--action);
            }
        }

    }
}