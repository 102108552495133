@use "abstracts" as *;
@use 'sass:color';

.article-card {
    display: block;
    text-decoration: none;
    color: var(--t-content);
    overflow: hidden;

    &:hover, &:focus {

        .article-card__image {
            transform: scale(1.05);
        }

        .article-card__heading {
            color: $coral;
        }

        .article-card__picture::after {
            width: 100%;
            height: 100%;
            transform: rotate(0deg) translate(0%, 0%);
        }
    }

    &__figure {
        display: block;
        width: 100%;
        margin: 0;
        overflow: hidden;
        position: relative;
    }

    &__picture {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &::after {
            content: "";
            
            width: 100px;
            height: 100px;
            background: $ocean-depths;
            display: block;
            position: absolute;
            top: 0;
            right: 0; 
            transform: rotate(30deg) translate(0%, -80%);
            transition: all 0.3s ease-in-out;
            opacity: 0.4;
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
        transform: scale(1);
        transition: transform 0.75s ease-in-out 0.25s;
    }

    &__heading {
        font-size: 1.1rem;
        font-weight: normal;
        color: $blue;
        transition: color 0.75s ease-in-out 0s;
    }

    &__meta {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;
        font-size: 0.9em;

        p {margin: 0;}
        
    }

    &__date {
        color:$grey;
        
        span {
            color: $blue;
        }
    }

    &__tag {
        text-transform: uppercase;
        font-weight: bold;
        color:$blue;
    }
}

.image-placeholder {
    position: relative;
    padding-top: 66.667%;
    background-color: var(--t-bg);


    &__content {
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20%; // Reduce the size of svg
    }
}
