@use "abstracts" as * ;

.copyright {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__text {
        color: $grey;
        margin: 0;
        font-size: .75rem;
    }
}