/* ======================================== */
/* Page Layout */
/* ======================================== */

@use '../abstracts' as *;

body {
    min-height: 100vh; 
}

/* ----- Site Grid ----- */

.site-layout {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: "header header header"
                         "main main main"
                         "footer footer footer"
    ;
}

// Grid elements
header {
    grid-area: header;
    position: relative;
    z-index: 3;
}

main {
    grid-area: main;
    position: relative;
    z-index: 2;
}

footer {
    grid-area: footer;
    position: relative;
    z-index: 1;
}

/* ----- Content Width ----- */

// Width limit for main content
.container {
    display: block;
    width: 100%;
    max-width: 100%; // OR 120rem = 1920px in 99% scenarios
    
    margin-left: auto;
    margin-right: auto;
}

/* ----- Navigation height ----- */
/* 
    Navigation can be sticky/fixed so update any sticky columns or other components to have top gap equal to nav height.
*/
:root {
    --sticky-nav-height: 84px;

    @include breakpoint(md) {
        --sticky-nav-height: 105px;
    }
}
