/* ======================================== */
/* Aspect Ratio */
/* ======================================== */

/* 
    Extra:
    Ratio     |       Value
    16:9      |       56.25%
    4:3       |       75%
    3:2       |       66.67%
    8:5       |       62.5%
*/

@use 'sass:math';  

.aspectratio-16-9 {
    float: left;
    display: block;
    padding-top: math.percentage(math.div(9, 16));
    width: 0;
}

.aspectratio-4-3 {
    float: left;
    display: block;
    padding-top: math.percentage(math.div(4, 3));
    width: 0;
}

.aspectratio-3-2 {
    float: left;
    display: block;
    padding-top: 67%;
    width: 0;
}

.aspectratio-1-1 {
    float: left;
    display: block;
    padding-top: 100%;
    width: 0;
}