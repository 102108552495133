@use '../abstracts/' as *;

.media-text {
    display: grid;
    grid-template-areas: "media"
                         "content";
    
    background-color: var(--t-bg);
    
    @include breakpoint(md) {
        grid-template-areas: "content media";
        grid-template-columns: repeat(2, minmax(0, 1fr));

        &--half {
            // Default state
        }

        &--wide {
            grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
        }

        &--short {
            grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
        }

        &--reverse {
            grid-template-areas: "media content";
    
            &.media-text--wide {
                grid-template-columns: 2fr 1fr;
            }
    
            &.media-text--short {
                grid-template-columns: 1fr 2fr;
            }
        }
    }

    &__content {
        @extend %content-horizontal-spacing;
        @extend %content-vertical-spacing;

        grid-area: content;
        display: flex;
        flex-direction: column;
        color: var(--t-content);

        &.align-y-start { 
            justify-content: flex-start;
        }
        
        &.align-y-center { 
            justify-content: center;
        }
        
        &.align-y-end { 
            justify-content: flex-end;
        }
    }
    
    &__media {
        grid-area: media;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    // Display types - as slider, grid, 1 large item
    &__grid {
        display: flex;
        flex-direction: column;
    }

    &__single {    
        display: grid;
        display: flex;
        justify-content: center;
        align-items: center;
        // place-content: center;
        height: 100%;
        max-height: calc(100vh - var(--sticky-nav-height));
        overflow: hidden;

        @include breakpoint(md) {
            position: sticky;
            top: var(--sticky-nav-height);
        }
    }
}

.sticky-content {

    @include breakpoint(md) {
        position: sticky;
        top: calc(var(--sticky-nav-height) + 5vw);
    }
}


// Single picture
.picture-featured {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__image {
        display: block;

        &--cover {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    
        &--contain {
            padding: 2rem;
            max-width: 100%;
            max-height: 100%;
        }
    }
}