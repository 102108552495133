/* ======================================== */
/* figure image */
/* ======================================== */

.figure-image {
    position: relative;
    width: 100%;
    margin: 3rem 0;

    &__picture {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: var(--t-bg--action);
    }

    &__img {
        width: 100%;
        display: block;

        &--portrait {
            width: auto;
            max-width: 100%;
            max-height: 60vh; // Something sensible for viewing
        }
    }

    &__figcaption {
        text-align: center;
        padding-top: 0.5em;
        opacity: 0.75;
        font-size: 0.875em;
        font-style: italic;
        color: var(--t-content);
    }
}
