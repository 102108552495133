@use "abstracts" as *;

.next-block {
    z-index: 99;
    position: absolute;
    bottom: 0;
    width: 3.125rem;
    height: 3.125rem;
    padding: 0.625rem 0;
    border: none;
    background: transparent;
    cursor: pointer;

    &--left {
        left: 5vw;
    }

    &--right {
        right: 5vw;
    }

    &:hover{
        background: transparent;

        svg {
            .svg-fill {
                // fill: var(t-standout-btn-content);
                fill: #b14440;
            }
        }
    }
    
    svg {
        width: 100%;
        height: 100%;

        .svg-fill {
            transition: all 0.2s ease-in-out;
            // fill: var(--t-btn-content);
            fill: #e65550;
        }
    }
}

// ----------------------------------------
// Add additional padding base on btn size
$btnScrollBlock--xs: 4rem;
$btnScrollBlock--sm: 5rem;
$btnScrollBlock--md: 4rem;
$btnScrollBlock--lg: max(5vw, 4rem);
$btnScrollBlock--mx: 5vw;

// map
$btnScrollBlock-sizes: (
    "xs-only": $btnScrollBlock--xs,
    "sm-only": $btnScrollBlock--sm,
    "md-only": $btnScrollBlock--md,
    "lg-only": $btnScrollBlock--lg,
    "mx-only": $btnScrollBlock--mx
);

.btnScrollBlockSize {
	padding-bottom: $btnScrollBlock--xs;

	@each $breakpoint, $size in $btnScrollBlock-sizes {
        @include breakpoint($breakpoint) {
            padding-bottom: $size;
        }
	}
}