/* ======================================== */
/* Block Layout */
/* ======================================== */

@use '../abstracts' as *;
@use 'sass:math';

.layout {
	position: relative;
	width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: var(--t-bg);
    color: var(--t-content);
    
    .layout:last-child {
        margin-bottom: 0;
    }
}

.layout--fullheight {
    display: flex;
    align-items: stretch;
    min-height: 100vh;
}

.layout--contained.layout--fullheight {
    min-height: calc(100vh - 2*#{$layout-gutter--xs});

    @each $breakpoint, $size in $layout-gutter-sizes {
        @include breakpoint($breakpoint) {
            min-height: calc(100vh - 2*#{$size});

        }
    }
}
//---------------------------------------------------------------
// Variants

.layout--default,
.layout--fullwidth,
.layout--stretched {
    margin-top: $layout-gutter--xs;

    @each $breakpoint, $size in $layout-gutter-sizes {
        @include breakpoint($breakpoint) {
            margin-top: $size;
        }
    }

    &:first-child {
        margin-top: 0;
    }
    
    & + & {
        margin-top: 0px;
    }

    & + .layout--contained {
        margin-top: $layout-gutter--xs;

        @each $breakpoint, $size in $layout-gutter-sizes {
            @include breakpoint($breakpoint) {
                margin-top: $size;
            }
        }
    }
}

.layout--stretched {
    .layout__inner {
        width: calc(100% - #{$layout-gutter--xs*2});

        @each $breakpoint, $size in $layout-gutter-sizes {
            @include breakpoint($breakpoint) {
                width: calc(100% - #{$size*2});
            }
        }
    }
}

.layout--contained {
    width: calc(100% - #{$layout-gutter--xs*2});
    margin-top: $layout-gutter--xs;
    margin-bottom: $layout-gutter--xs;

    @each $breakpoint, $size in $layout-gutter-sizes {
        @include breakpoint($breakpoint) {
            margin-top: $size;
            margin-bottom: $size;
            width: calc(100% - #{$size*2});
        }
    }

    &:first-child,
    & + & {
        margin-top: 0;
    }

    & + .layout--default,
    & + .layout--fullwidth,
    & + .layout--stretched {
        margin-top: 0;
    }
}


//---------------------------------------------------------------
// Inner

.layout__inner {
	position: relative;
	width: 100%;
    margin-left: auto;
    margin-right: auto;
	z-index: 2;

    // Columns
    &--columns {

        @include breakpoint(md) {
            display: flex;
            flex-direction: row; // Default allows for child blocks to appear in columns
        }
    }
}


//---------------------------------------------------------------
// Column

.layout__column {

	@include breakpoint(md) {
		flex-basis: 0;
		flex-grow: 1;

		display: flex;
		flex-direction: row;
	}
}

//---------------------------------------------------------------
// Theme specific combinations of layouts - spacing
//---------------------------------------------------------------

// Remove unwanted spacing on layouts in particular scenarios
// Lots of if, thens, and buts here, sorry!

@for $i from 1 through length($array-themes) {

    $class-name: nth($array-themes, $i);

    .layout--contained[data-theme-contents="#{$class-name}"] {
        
        // Layout that follows
        & + .layout--contained {

            // is same theme colour
            &[data-theme-contents="#{$class-name}"] {
                margin-top: 0;
            }
        }

        // Layout that follows
        & + .layout--default,
        & + .layout--fullwidth,
        & + .layout--stretched {
            margin-top: 0;
        }
    }

    //----------------------------------------------------

    .layout--default[data-theme-contents="#{$class-name}"],
    .layout--fullwidth[data-theme-contents="#{$class-name}"],
    .layout--stretched[data-theme-contents="#{$class-name}"] {

        // Layout that follows
        & + .layout--contained {

            &[data-theme-contents="#{$class-name}"] {
                margin-top: $layout-gutter--xs;

                @each $breakpoint, $size in $layout-gutter-sizes {
                    @include breakpoint($breakpoint) {
                        margin-top: $size;
                    }
                }
            }
        }
    }

    .layout--stretched[data-theme-contents="#{$class-name}"] {
        
        // Same layout and colour that follows
        & + .layout--stretched[data-theme-contents="#{$class-name}"] {
            margin-top: math.div(-$layout-gutter--xs, 2);

                @each $breakpoint, $size in $layout-gutter-sizes {
                    @include breakpoint($breakpoint) {
                        margin-top: math.div(-$size, 2);
                    }
                }
        }
    }
}

// NB. $default-theme-equivalent variable set in abstracts/_themes.scss

// Override - Non default layouts
.layout--contained:not([data-theme-contents="#{$default-theme-equivalent}"]) {

    // Layout that follows
    & + .layout--default,
    & + .layout--contained,
    & + .layout--fullwidth,
    & + .layout--stretched {

        // is default
        &[data-theme-contents="#{$default-theme-equivalent}"] {
            margin-top: -$layout-gutter--xs;

            @each $breakpoint, $size in $layout-gutter-sizes {
                @include breakpoint($breakpoint) {
                    margin-top: -$size;
                }
            }
        }
    }
}

// Override - default layouts 
.layout--contained[data-theme-contents="#{$default-theme-equivalent}"] {
    
    // Any layout that follows - default or not default
    & + .layout--default[data-theme-contents="#{$default-theme-equivalent}"],
    & + .layout--contained[data-theme-contents="#{$default-theme-equivalent}"],
    & + .layout--fullwidth[data-theme-contents="#{$default-theme-equivalent}"],
    & + .layout--stretched[data-theme-contents="#{$default-theme-equivalent}"],
    // Need below selectors to override greater specificity further up
    & + .layout--default:not([data-theme-contents="#{$default-theme-equivalent}"]),
    & + .layout--contained:not([data-theme-contents="#{$default-theme-equivalent}"]),
    & + .layout--fullwidth:not([data-theme-contents="#{$default-theme-equivalent}"]),
    & + .layout--stretched:not([data-theme-contents="#{$default-theme-equivalent}"]) {
        margin-top: -$layout-gutter--xs;

        @each $breakpoint, $size in $layout-gutter-sizes {
            @include breakpoint($breakpoint) {
                margin-top: -$size;
            }
        }
    }
}


    
// Override - Non default layouts
.layout--default:not([data-theme-contents="#{$default-theme-equivalent}"]),
.layout--fullwidth:not([data-theme-contents="#{$default-theme-equivalent}"]),
.layout--stretched:not([data-theme-contents="#{$default-theme-equivalent}"]) {
    
    // Layout that follows
    & + .layout--contained {

        // is default
        &[data-theme-contents="#{$default-theme-equivalent}"] {
            margin-top: 0;
        }
    }
}

// Override - default layouts
.layout--default[data-theme-contents="#{$default-theme-equivalent}"],
.layout--fullwidth[data-theme-contents="#{$default-theme-equivalent}"],
.layout--stretched[data-theme-contents="#{$default-theme-equivalent}"] {

    // Layout that follows
    & + .layout--contained {

        // is not default
        &:not([data-theme-contents="#{$default-theme-equivalent}"]) {
            margin-top: 0;
        }

        // is default
        &[data-theme-contents="#{$default-theme-equivalent}"] {
            // margin-top: -$layout-gutter--xs;
            margin-top: 0;
    
            @each $breakpoint, $size in $layout-gutter-sizes {
                @include breakpoint($breakpoint) {
                    // margin-top: -$size;
                    margin-top: 0;
                }
            }
        }

    }
}
