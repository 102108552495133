/* ---------- Slick Slider ---------- */
/* Be carefull editing this part */

/* Wreap each slider with this class to prevent width blowout */
.slider-wrapper {
    overflow: hidden;
    display: grid;
    min-width: 0px;
    min-height: 0;
}

.slick-slider {
    min-width: 0;
    min-height: 0;
}

.slick-track {
    display: flex !important;
}
  
.slick-slide {
    height: auto;
}
/* ---------- END Slick Slider ---------- */

// Dots
.slick-dots {
    position: absolute;
    bottom: -1rem;
    list-style: none;
}

.media-text__media {
    .slick-dots {
        position: absolute;
        bottom: 1rem;
        list-style: none;
    }
    .slick-dotted.slick-slider {
        margin-bottom: 0; 
    }
}