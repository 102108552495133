/* ======================================== */
/* Block Advanced */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

.block-advanced {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    @include breakpoint(sm) {
        row-gap: 4rem;
    }

    &__top,
    &__intro,
    &__aside,
    &__body {
        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__top {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        row-gap: 2rem;

        @include breakpoint(sm) {
            flex-direction: row;
            row-gap: 2rem;
            column-gap: 4rem;
        }
    }

    &__intro,
    &__aside {
        @include breakpoint(sm) {
            flex-basis: calc(50% - 2rem);
            position: sticky;
            top: calc(var(--sticky-nav-height) + 5vw);
            display: grid;
            align-items: flex-start;
            justify-content: flex-end;
        }
    }

    &__intro {
        // background: yellow;
    }

    &__aside {
        // background: red;
    }

    &__body {
        // background: green;
        width: 100%;

        // @include breakpoint(lg) {
        //     width: math.percentage(math.div(11, 12));
        // }

        // @include breakpoint(mx) {
        //     width: math.percentage(math.div(10, 12));
        // }
    }
}