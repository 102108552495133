@use "abstracts" as *;

// $box-shadow: 0px 5px 5px 5px #e5e5e5;

// Intro
.profile-wrapper{
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;
}

// Profile Container
.profile-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    grid-auto-flow: dense; // Do not change this.
    gap: 2rem;

    @include breakpoint(mx) {
        grid-template-columns: repeat(4, 1fr);
    }
}

// Styles for Card
.card-profile {
    display: grid;
    cursor: pointer;
    border: none;

    &.no-expander {
        cursor: auto;
    }

    &.is-inactive {
        opacity: 1;

        animation-name: inactive;
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    &.is-active {

        &.card-triangle::after {
            display: block;
            content: "";
            position: absolute;
            left: calc(50% - 1rem);
            bottom: -2.1rem;
            width: 1.75rem;
            height: 1.75rem;
            background-color: var(--t-standout-bg);
            clip-path: polygon(50% 0%, 10% 100%, 90% 100%);

            transform: translateY(35%);
            opacity: 0;

            animation-name: move-up;
            animation-duration: 0.25s;
            animation-delay: 0.65s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
    }

    &__inner {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.25rem;
        padding: 2rem 1rem;
        height: 100%;
        box-sizing: border-box;
        text-align: center;
        background-color: var(--t-standout-bg);
        color: var(--t-standout-content);
        
        &--landscape {
            flex-direction: row;
            text-align: left; 
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__img-wrapper {

    }

    &__img {

        picture {
            display: block;
            overflow: hidden;
            border-radius: 50%;
        }

        img {
            display: block;
            border-radius: 50%;
            object-fit: cover;
            transition: transform 0.3s ease-in-out;
        }
    }

    &__img-fallback {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: var(--t-bg);

        @include breakpoint(lg) {
            width: 200px;
            height: 200px;
        }

        svg {
            width: 35px;
        }

        .theme-icon-stroke {
            stroke: var(--t-standout-bg);
        }
    }

    &__title {
        font-weight: 900;
    }

    &__position {
        font-weight: 400;
    }
}

// Hovers etc. only when there is content to expand
.card-profile:not(.no-expander) {
    
    &:hover, &:focus {
        
        .card-profile__img {
    
            img {
                transform: scale(1.1);
            }
        }
    }

}

.card-profile-expander {
    display: none;
    position: relative;    
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    box-shadow: 0px 5px 5px 5px var(--t-content-shadow);
    background-color: var(--t-standout-bg);
    color: var(--t-standout-content);

    opacity: 0;
    transform: scale(0.5);

    &.is-active {
        display: flex;
        grid-column: 1 / -1;

        animation-name: expand;
        animation-duration: 0.5s;
        animation-delay: 0.25s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    &__btn-close {
        background: none;
        border: none;
        position: absolute;
        right: 0.75rem;
        top: 0.75rem;

        svg {
            fill: var(--t-standout-content);
        }

        &:hover {
            cursor: pointer;
        }
    }
}

// Animation for Opend Card
@keyframes expand {
    0% {
        transform: scale(0.75);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

// Animation for Inactive Cards
@keyframes inactive {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.75;
    }
}

// Triangle Animation
@keyframes move-up {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}