
.featured-image {
    position: relative;
    display: flex;
    justify-content: center;
    text-decoration: none;
    overflow: hidden;       // Hides content on hover -> scale

    &--cover {
        height: 100vh;
    }

    &--contain {
    
    }

    &__picture {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;  // Use focus in cms for best result
        transition: all 0.5s ease-in-out;
        display: block;


        &--cover {
            // height: 100vh;
        }

        &--contain {
            padding: 2em;
            max-width: 400px;
            object-fit: contain;
            object-position: center;
            margin: 0 auto;
            display: flex;
            height: auto;
        }

    }

    &__text {
        position: absolute;
        bottom: 0;
        margin-bottom: 2rem;
        padding: 1rem 3rem;
        background: #fff;
        color: var(--t-content,#000);
        font-weight: 700;
        text-transform: uppercase;
    }  
}

/* ------------------------------------------- */
/*  HOVER EFFECTS                              */
/* ------------------------------------------- */

.featured-image {

    &:hover, 
    &:active, 
    &:focus {

        // .featured-image__picture {
    
            .featured-image__image {
                transform: scale(1.05);
            }
        // }
    }
}

/* ------------------------------------------- */
/*  OVERLAY                                    */
/* ------------------------------------------- */

.image-overlay {

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent 40%);
        opacity: 1;
        pointer-events: none;
    }
}
