.link-advanced-list {

}

.link-advanced {
    text-decoration: none;

    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    line-height: 1.75rem;
    color: var(--t-content);
    transition: all 0.2s ease-in-out;

    &:hover, &:focus {
        color: var(--t-link--action);

        .link-advanced__icon {

            .theme-icon-opacity {
                opacity: 1;
            }
            
            .theme-icon-bg {
                fill: var(--t-link--action);
            }
        }
    }

    &__text {
        font-weight: bold;
    }

    &__description {

    }

    &__text-wrapper {

    }

    &__icon {

        .theme-icon-opacity {
            opacity: 0.5;
            transition: all 0.2s ease-in-out;
        }
        
        .theme-icon-bg {
            fill: var(--t-content);
            transition: all 0.2s ease-in-out;
        }
    }
}