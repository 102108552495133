/* ======================================== */
/* footer */
/* ======================================== */
@use '../abstracts' as *;

.footer {

    @extend %content-horizontal-spacing;
    @extend %content-vertical-spacing;

    background-color: var(--t-bg);

    @include breakpoint(md) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 2rem 4rem;
    }

    &__heading {
        color: var(--t-content);
        grid-column: span 2;
        margin: 0;
    }

    &__textarea {
        color: var(--t-content);
        max-width: 50ch;
    }

    &__content {
        // margin-bottom: 2rem;
    }

    &__links {
        display: flex;
    }

}

.footer-navigation {

    ul {

        li {
            list-style: none;

            a {
                text-decoration: none;
                font-weight: bold;
            }
        }
    }
}

.utility {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 2rem;
    gap: 0.5rem;
    background-color: var(--t-bg);

    @extend %content-horizontal-spacing;

    a {
        //color: $grey;

        &:hover, &:focus {
            //color: black;
        }
    }

    &__navigation {

        ul {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            padding: 0;

            @include breakpoint(xs) {
                flex-direction: row;
            }

            li {
                list-style: none;
                padding-right: 1rem;
                margin:0;
    
                a {
                    text-decoration: none;
                    font-weight: bold;
                    font-size: 0.75rem;
                    color: grey;
                    transition: all 0.2s ease-in-out;

                    &:hover, &:focus {
                        color: $black;
                    }
                }
            }
        }
    }
}