/* ======================================== */
/* Block Theme Test */
/* ======================================== */

@use 'abstracts' as *;

.block-theme-test {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;

    display: block;
    max-width: 100ch;

    .link-download-list {
        margin-bottom: 4rem;
    }
}

