/* ======================================== */
/* link-btn */
// fancier links with icons etc
/* ======================================== */

@use 'sass:color';
@use "abstracts" as * ;

.download-label {
    margin-bottom: 1rem;
    font-weight: 900;
}

.link-download {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
    width: fit-content;
    color: var(--t-linkbutton);
    text-decoration: none;
    font-weight: 900;
    margin-top: -2px; // Remove doubled borders

    .svg {
        height: 1.5rem;
    }

    .svg__stroke {
        stroke: var(--t-linkbutton-icon-content);
    }

    &:hover,
    &:focus {
        color: var(--t-linkbutton--action);

        .svg__stroke {
            stroke: var(--t-linkbutton-icon-content--action);
        }
    }

    // Modifiers
    &.reverse {
        flex-direction: row-reverse;
    }

    &.wide {
        display: flex;
        justify-content: space-between;
    }

    &.border {
        border-top: 2px solid;
        border-bottom: 2px solid;
        border-color: var(--t-linkbutton);

        &:hover,
        &:focus {
            z-index: 2;
            border-color: var(--t-linkbutton--action);
        }
    }
}

// Inside matrix repeater
.link-download-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

// ------------------------------
// Link Arrow Right
.link-arrow-right {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    text-decoration: none;
    color: var(--t-link);

    &:hover, 
    &:focus {
        color: var(--t-link);

        .theme-icon-opacity {
            opacity: 1;
        }

        .theme-icon-bg {
            fill: var(--t-content);
        }
    }

    &__icon {
        display: flex;
        // height: 1.25rem;
    }

    .theme-icon-opacity {
        transition: all .2s ease-in-out;
        opacity: 0.75;
    }

    .theme-icon-bg {
        transition: all .2s ease-in-out;
        fill: #{color.scale($white, $lightness: -10%)};;
    }
}

.link-arrow-down {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    text-decoration: none;
    color: var(--t-link);
    

    &:hover, 
    &:focus {
        color: var(--t-link);

        .theme-icon-opacity {
            opacity: 1;
        }

        .theme-icon-bg {
            fill: var(--t-content);
        }
    }

    &__icon {
        display: flex;
        transform: rotate(90deg);
    }

    .theme-icon-opacity {
        transition: all .2s ease-in-out;
        opacity: 0.75;
    }

    .theme-icon-bg {
        transition: all .2s ease-in-out;
        fill: #{color.scale($white, $lightness: -10%)};;
    }
}

// BACK
.back-btn-wrapper {
    @extend %content-horizontal-spacing ;
    @extend %content-vertical-spacing;
}

.back-btn {
    display: flex;
    align-items: center;
    width: fit-content;
    text-decoration: none;
    color: var(--t-link);
    transition: all 0.2s ease-in-out;

    &__text {
        font-size: 1.25rem;
        font-weight: bold;
    }

    &__icon {
        margin-right: 0.5rem;
    }
    
    .svg__stroke {
        transition: all 0.2s ease-in-out;
        stroke: var(--t-link);
    }

    &:hover {
        color: var(--t-link--action);

        .svg__stroke {
            stroke: var(--t-link--action);
        }
    }
}

.back-btn-mt {
    margin-top: 0;
}