/* ======================================== */
/* animation */
/* ======================================== */

/* Don't play any css animations until the page is loaded */
.jsPreLoad * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
}

$reveal-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);

/* --------------------------------------------------------------------------------------------*/
/* WAYPOINTS */
/* --------------------------------------------------------------------------------------------*/

// Fade in
[data-animate="fadeIn"] {
    opacity: 0;
    transition: all 0.5s 0.3s ease-out;

    .no-js &,
    &.animate {
        opacity: 1;
    }
}

// Fade in up
[data-animate="fadeInUp"] {
    opacity: 0;
    transform: translateY(2vh);
    transform-origin: center center;
    transition: all 0.5s 0.3s ease-out;

    .no-js &,
    &.animate {
        opacity: 1;
        transform: translateY(0);
    }
}

// Fade in left
[data-animate="fadeInLeft"] {
    opacity: 0;
    transform: translateX(-2vh);
    transform-origin: center center;
    transition: all 0.5s 0.3s ease-out;

    .no-js &,
    &.animate {
        opacity: 1;
        transform: translateX(0);
    }
}

// Fade in right
[data-animate="fadeInRight"] {
    opacity: 0;
    transform: translateX(2vh);
    transform-origin: center center;
    transition: all 0.5s 0.3s ease-out;

    .no-js &,
    &.animate {
        opacity: 1;
        transform: translateX(0);
    }
}

// Reveal left
[data-animate="revealLeft"] {
    opacity: 0;
    clip-path: polygon(0% 0%, 0.1% 0%, 0.1% 100%, 0% 100%);
    transition: all 0.5s 0.3s $reveal-timing-function;

    .no-js &,
    &.animate {
        opacity: 1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}

// Reveal right
[data-animate="revealRight"] {
    opacity: 0;
    clip-path: polygon(99.9% 0%, 100% 0%, 100% 100%, 99.9% 100%);
    transition: all 0.5s 0.3s $reveal-timing-function;

    .no-js &,
    &.animate {
        opacity: 1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}

// Reveal bottom
[data-animate="revealBottom"] {
    opacity: 0;
    clip-path: polygon(0% 99.9%, 100% 99.9%, 100% 100%, 0% 100%);
    transition: all 0.5s 0.3s $reveal-timing-function;

    .no-js &,
    &.animate {
        opacity: 1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}

// Reveal top
[data-animate="revealTop"] {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0.1%, 0% 0.1%);
    transition: all 0.5s 0.3s $reveal-timing-function;

    .no-js &,
    &.animate {
        opacity: 1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}

// Reveal left top
[data-animate="revealLeftTop"] {
    opacity: 0;
    clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%);
    transition: all 0.5s 0.3s $reveal-timing-function;

    .no-js &,
    &.animate {
        opacity: 1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}

// Reveal right top
[data-animate="revealRightTop"] {
    opacity: 0;
    clip-path: polygon(100% 0%, 100% 0%, 100% 0%, 100% 0%);
    transition: all 0.5s 0.3s $reveal-timing-function;

    .no-js &,
    &.animate {
        opacity: 1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}

// Reveal right bottom
[data-animate="revealRightBottom"] {
    opacity: 0;
    clip-path: polygon(100% 100%, 100% 100%, 100% 100%, 100% 100%);
    transition: all 0.5s 0.3s $reveal-timing-function;

    .no-js &,
    &.animate {
        opacity: 1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}

// Reveal left bottom
[data-animate="revealLeftBottom"] {
    opacity: 0;
    clip-path: polygon(0% 100%, 0% 100%, 0% 100%, 0% 100%);
    transition: all 0.5s 0.3s $reveal-timing-function;

    .no-js &,
    &.animate {
        opacity: 1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}