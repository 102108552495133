/* ======================================== */
/* header */
/* ======================================== */
@use '../abstracts' as *;

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    transition: all 0.5s ease-in-out;
    z-index: 999;

    @extend %content-horizontal-spacing;

    &.is-active {
        background: $white;
        box-shadow: 0px 2px 10px rgba($ocean-depths, .1);

        .header-image {

            .logo-svg {

                &__fill {
                    fill:#005670
                }
        
                &__fill-alt {
                    fill:#004680
                }
            }
            
        }
    }
}

.header-image {

    .logo-svg {

        padding:1em;
        width: 70px;
        height: auto;
        @include breakpoint(md) {
            width: 80px;
        }

        &__fill {
            fill: $white;
        }

        &__fill-alt {
            fill: $white;
        }
    }
}

.burger-menu {
    display: block;
    margin-left: 2rem;

    @include breakpoint(md) {
        display: none !important;
    }
}

/* Icon 2 */

#nav-icon2 {
    display: block;
    margin-left: 2rem;
    position: relative;
    width: 50px;
    height: 40px;
    background: transparent;
    border: none;
    padding: 0;
    
    &:hover {
        cursor: pointer;
    }
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 5px;
  width: 50%;
  background: $white;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
.header.is-active {
    #nav-icon2 span {
        background: var(--t-link);
    }
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
//   border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left: 0px;
//   border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 15px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 30px;
}

#nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 22px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 22px;
}