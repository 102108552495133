@use '../abstracts' as *;

.block-cut:after {
    --shape-size: 60px; 
    content: "";
    position: absolute;
    left: 0;
    bottom: 1px;
    width: var(--shape-size);
    height: var(--shape-size);
    transform: translateY(100%);
    z-index: 20;
    background: var(--t-bg);
    clip-path: polygon(0 0, 0 50%, 100% 0);
    box-sizing: border-box;

    @include breakpoint(md) {
        --shape-size: 80px; 
    }

    @include breakpoint(lg) {
        --shape-size: 100px; 
    }

    @include breakpoint(mx) {
        --shape-size: 150px; 
    }
}

