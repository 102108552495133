@use "abstracts" as *;
@use 'sass:color';

// Filter
.filter {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1.125rem;

    &__category {
        color: #{$ocean-depths};
        display: inline;
    }

    &__list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        text-decoration: none;
    }

    &__list-item {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__list-link {
        text-decoration: none;
        color: #{color.scale($ocean-depths, $lightness: 20%)};
        transition: all 0.25s ease-out;

        &:hover, &:focus {
            color: #{$ocean-depths};
        }
    }
}

// pagination list
.pagination-list {

    ul {
        display: flex;
        justify-content: center;
        gap: 1rem;
        padding: 0;
        list-style: none;

        li {
            border: solid 1px;
            border-color: var(--t-btn-border);
            background: var(--t-btn-bg);
            margin: 0;
            padding: 0.5rem 0.75rem;
            transition: all 0.2s ease-in-out;

            &.active, &:hover, &:focus {
                border-color: var(--t-btn-border--action);
                background: var(--t-btn-bg--action);

                a {
                    color: var(--t-btn-content--action);
                }
            }

            a {
                color: var(--t-btn-content);
                text-decoration: none;
            }
        }
    }
}

.no-match {
    border: solid 2px;
    border-style: dashed;
    border-color: $ocean-depths;
    padding: 2rem;
    grid-column: 1/-1;

    &__heading {
        color: $ocean-depths;
    }

    &__textarea {
        color: $black;
    }
}

// Additional elements / blocks
.articles-heading {
    color: var(--t-content-highlight);
}