/* --------------------------------------------------------------------------------------------*/
/* TOGGLE */
/* Works with custom toggle .jsToggle and .jsToggleTarget variations */
/* --------------------------------------------------------------------------------------------*/

.toggle-target {
    display: none;
}

.is-visually-hidden {
    opacity: 0;
    transition: opacity 0.5s 0.2s;
}

.is-visually-hidden-mobile {
    opacity: 0;
    transition: opacity 0.5s 0.2s;

    // @include breakpoint(sm) {
    //     opacity: 1;
    // }
}

.showme {
    opacity: 1;
}

.toggle-button {
    float: right;
}


/* --------------------------------------------------------------------------------------------*/
/* ACCORDION */
/* Works with custom JS toggle .jsAccordionTrigger and .jsAccordionContent */
/* --------------------------------------------------------------------------------------------*/

$accordion-arrow-size: 40px;

.accordion-wrapper {
    // @extend %content-vertical-spacing;
    // @extend %content-horizontal-spacing;
    width: 100%;
}



.accordion {
    display: block;
    border-top: 1px solid;
    margin-bottom: 3rem;
}

.accordion--mobile {
    // @include breakpoint(sm) {
    //     border: none;
    // }
}

.accordion__item {
    margin-bottom: 0; // overlap borders, must be equal to border botom value
    border-bottom: 1px solid;
}

.accordion-trigger {
	position: relative;
    display: block;
    background: none;
	cursor: pointer;
    padding: 1em 0;

    transform-origin: center center;
    text-transform: none; // override base button style

    &:active {
        transform: none;  // override base button style
    }

	&.is-active {
		// color: $text-color--highlight; // Color also set in _themes.scss

		.accordion-trigger__icon {
			transform: rotate(45deg);
		}
    }

}

.accordion-trigger__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.accordion-trigger__text {
    padding-right: 1em;
    font-weight: bold;
    font-size: 1.25rem;
}

.accordion-trigger__icon {
    flex-shrink: 0;
    transition: all 0.1s 0s;
    transform: rotate(0);
    transform-origin: center center;
    width: 1.875rem; // 30px;
    height: 1.875rem; // 30px

    svg {
        width: 100%;
        vertical-align: top;

        &__fill {
            fill: var(--t-btn-content);
        }

        &__stroke {
            stroke: transparent;
        }
    }
}

.accordion__content {
    display: none;
    padding: 0 0 2em;
    width: calc(100% - 4rem);
}