@use "abstracts" as * ;

.social-media-list {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.social-media-link {
    max-width: 2rem;

    .icon-social-media {
        width: 100%;
        height: auto;
    }

    .theme-icon-bg {
        fill: $grey;
        transition: 0.2s ease-in-out;
    }

    &:hover {
        .theme-icon-bg {
            fill: $black;
        }
    }
}

