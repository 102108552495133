/* ======================================== */
/* table */
/* ======================================== */
@use '../abstracts' as *;

// Wrapper for table
.table-responsive {
    overflow-x: scroll;
    margin-bottom: 1rem;

    table {
        margin-bottom: 0;
    }
}


table {

}

//
// Tables
// --------------------------------------------------

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
	vertical-align: middle;
	line-height: 120%;
	font-size: 1em;
}

//Condensed
.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td{
	padding: 0.5em 1em;
}

.table-striped {
	// See theme.scss
}

.table-striped > thead > tr > th,
.table-striped > thead > tr > td,
.table-striped > tbody > tr > th,
.table-striped > tbody > tr > td,
.table-striped > tfoot > tr > th,
.table-striped > tfoot > tr > td{
	border-bottom: 0;
}

.table,
table {
	width: 100%;
	border: 0px solid $light-grey;
    margin-bottom: 1em;
    border-collapse: collapse;

	 thead, th {
		background-color: $ocean-depths;
		color: white;
	 }
    
    // tr:first-of-type {
    //     td {
    //         padding: 1em;
    //     }
    // }
	tr {
		border-bottom: 1px solid $light-grey;
	}

	td {
		padding: 1em;
	}
	p {
		margin: 0;
	}
	tbody {
        color: black;
        background-color: white;

        tr:nth-child(even) {
            background-color: rgba(0, 86, 112, 0.1);
        }

		tr:hover,tr:focus {
			    background: rgba(0, 86, 112, 0.2);
				transition: all 0.3s ease;

    
		}
    }
}


//  .inner_content table {
//   background-color: #fafafa;
//   padding: 20px;
// }
//  .inner_content table tr {
//   -webkit-transition: all 0.2s ease-in-out;
//   -o-transition: all 0.2s ease-in-out;
//   transition: all 0.2s ease-in-out;
//   border-bottom: #fff 2px solid !important;
// }
//  .inner_content table tr th {
//   padding: 25px 20px 5px 20px;
//   text-align: left;
//   font-size: 15px;
//   line-height: 25px;
// }
//  .inner_content table tr td {
//   font-size: 15px;
//   line-height: 25px;
//   color: #555555;
//   padding: 10px 0;
//   vertical-align: top;
//   padding: 15px 20px;
//   border-top: 0 !important;
//   border-right: 0 !important;
//   border-left: 0 !important;
//  /* border-bottom: #fff 2px solid !important;*/
//   text-align: left;
//   position: relative;
//   overflow: hidden;
// }
// .inner_content table tr td a,  .inner_content table tr th a {
//   word-break: initial!important;
// }
// .inner_content table tr td.bold {
//   font-weight: bold;
// }
// .inner_content table tr td a {
//   color: #555;
//   position: relative;
// }

// // --------------------------------------------------
// // Action column

// .table-action {

// 	@include breakpoint(md) {
// 	    text-align: center;
// 	}
// }

// th.table-action {
// 	background: $light-grey;
// }


// // --------------------------------------------------
// // Action column

// .table-title-cell {
// 	font-weight: 700;
// }


// // --------------------------------------------------
// // Responsive tables styling

// .stacktable {
// 	width: 100%;

// 	&.large-only {
// 		display: table;

// 		@include breakpoint(md-max) {
// 			display: none;
// 		}
// 	}

// 	&.small-only {
// 		display: none;

// 		th {
// 			border-top: none;
// 		}

// 		@include breakpoint (md-max) {
// 			display: table;
// 		}
// 	}
// }

// .st-head-row {
// 	text-align: center !important;
// 	// See them.scss
// }

// .st-head-row.st-head-row-main {
// 	display: none;
// }

// .st-key {
// 	width: 50%;
// }

// .st-val {
// 	width: 50%;
// }


// .responsive-table {
// 	overflow: auto;
// 	scroll-snap-type: both mandatory;
// 	height: 80vh;
// 	max-width: 100%;
// 	overflow-x:auto;


// 	table {
// 		border-collapse: collapse;
// 		border: 0;
// 		table-layout: fixed; 
// 		width: 100%;
// 	}
// 	th,
// 	td {
// 		//   border: 1px solid #aaa;
// 		background-clip: padding-box;
// 		scroll-snap-align: start;
// 	}
// 	tbody tr:last-child th,
// 	tbody tr:last-child td {
// 		border-bottom: 0;
// 	}
// 	thead {
// 		z-index: 1000;
// 		position: relative;
// 	}

// 	thead tr {
// 		position: sticky;
// 		// top: var(--sticky-nav-height);
// 		z-index: 2;
// 	}
// 	th,
// 	td {
// 		padding: 0.6rem;
// 		min-width: 6rem;
// 		text-align: left;
// 		margin: 0;
// 	}
// 	thead th {
// 		position: sticky;
// 		top: 0;
// 		border-top: 0;
// 		background-clip: padding-box;
// 	}

// 	tbody th {
// 		background-clip: padding-box;
// 		border-left: 0;
// 	}
// 	tbody {
// 		z-index: 10;
// 		position: relative;
// 	}
// 	tbody th {
// 		position: sticky;
// 		left: 0;
// 	}
// 	thead th,
// 	tbody th {
// 	//   background-color: #f8f8f8;
// 	}
// }


.ft-table {
	&__head, thead {

        // text-transform: uppercase;
    }
    
    &__body, tbody {
        // color: black;
        // background-color: white;

        // tr:nth-child(even) {
        //     background-color: rgba(0, 86, 112, 0.1);
        // }
    }

    th, td {
        // min-width: 150px;
        // min-width: 300px;
        padding: 1rem 0.25rem;
    }

    th {
        // background-color: #005670;
        // color: white;
        // position: sticky;
        // top: 0;
    }
}

.table-block {
    display: flow-root;
	// @extend %content-horizontal-spacing;
	@extend %content-vertical-spacing;
	// position: absolute;
	@include breakpoint(sm-max) {
		max-width: 90vw;
	// width: auto!important;
	}
}


.ft-table--wrap {
	width: 100%;
    overflow-x: scroll;
}
	table.dataTable {
		position: static;
	}
	table.ft-table {
	// width: auto!important;
		tbody {	
			@include breakpoint(sm-max) {
				text-align: center;
			}
		}
	}

	table.ft-table{
		background-color: transparent;
		border-collapse: collapse;
		border-spacing: 0;
		clear: both;
		// margin: 0 auto;
		width: 100%;
		table-layout: auto;
		margin-bottom: 10px;
		overflow: auto;
		padding: 20px;
		outline: none !important;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;

		
	}
	table.ft-table tr {
		-webkit-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		border-bottom: #fff 2px solid !important;
		&:hover, &:focus {
			outline: none !important;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
		}

	}
	table.ft-table thead > tr > th {
		//color: #333333;
		// border: 1px solid #cccccc;
		border-bottom-width: 2px;
		font-weight: 700;
		padding: 10px 20px;
		text-align: center;
		cursor: pointer;
		// background: #ffffff none;
		-webkit-transition: .5s;
		transition: .5s;
		
		overflow: hidden;
		text-overflow: ellipsis;
		// white-space: nowrap;
	}

	table.ft-table tbody > tr > td {
		text-align: center;
	}


	table.ft-table tr:nth-child(odd) {
		// background-color: #f5f5f5;
	}
	table.ft-table tr:nth-child(even){
		// background-color: #ffffff;
	}

