@use 'sass:color';
@use '../abstracts' as *;

.box-image {
    position: relative;
    display: flex;
    justify-content: center;
    text-decoration: none;
    overflow: hidden;       // Hides content on hover -> scale

    &--cover {
        height: 100%;
    }

    &__picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;  // Use focus in cms for best result
        transition: all 0.5s ease-in-out;
    }

    &__text {
        padding: 1rem 3rem;
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
        z-index: 2;
    }

    &__text-wrapper {
        display: flex;
        align-self: flex-end;
        justify-content: center;
        width: 100%;
        text-align: center;
        position: relative;

        &:before {        
            content: "";    
            background: #005670;
            display: block; 
            width: 150%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.4;
            clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
            transition: all .6s ease-in-out;
            transform: translateX(-100%);
            z-index: 1;
        }
    }
}


/* ------------------------------------------- */
/*  HOVER EFFECTS                              */
/* ------------------------------------------- */

.box-image {

    &:hover, 
    &:active, 
    &:focus {
    
        .box-image__image {
            transform: scale(1.05);
        }

        .box-image__text-wrapper  {
            &::before {
                transform: translateX(0%);
            }
        }
    }
}

/* ------------------------------------------- */
/*  OVERLAY                                    */
/* ------------------------------------------- */

.image-overlay {

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent 40%);
        opacity: 1;
        pointer-events: none;
    }
}

.fallback-media {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}