/* ======================================== */
/* webfonts */
/* ======================================== */


/* Metropolis Condensed */

@font-face {
    font-family: 'Metropolis';
    src: url('../../webfonts/metropolis/Metropolis-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../../webfonts/metropolis/Metropolis-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../../webfonts/metropolis/Metropolis-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../../webfonts/metropolis/Metropolis-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../../webfonts/metropolis/Metropolis-Light.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../../webfonts/metropolis/Metropolis-LightItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../../webfonts/metropolis/Metropolis-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../../webfonts/metropolis/Metropolis-SemiBoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Metropolis';
    src: url('../../webfonts/metropolis/Metropolis-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../../webfonts/metropolis/Metropolis-MediumItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}