@use "abstracts" as *;
@use 'sass:color';

.article-grid {
    display: grid;
    gap: 5vw;
    
    padding-right: $content-block-pillarbox--xs;
    @extend %content-left-spacing;

    @include breakpoint(sm) {
        padding-right: $content-block-pillarbox--sm;
    }

    @include breakpoint(md) {
        grid-template-columns: 1fr minmax(350px, 25%);
        padding-right: 0;
    }
}

.article-aside {

    &__sticky {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    @include breakpoint(md) {
        // Move it to side on this break point
        order: 1;

        display: flex;
        flex-direction: column;
        gap: 2rem;

        &__sticky {
            position: sticky;
            top: var(--sticky-nav-height);
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
    }
}

// Meta - aside content
.meta-box {
    padding: 2rem;
    background-color: rgb(0 70 128 / 10%);

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    &__tag {
        margin-right: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.5);
    }

    &__read-time {
        min-width: 120px;
        color: rgba(0, 0, 0, 0.5);
    }

    &__date {
        font-weight: bold;
        margin-bottom: 1rem;
    }

    &__files {
        display: flex;
        flex-direction: column;
    }
}

.meta-author {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    background-color: rgb(0 70 128 / 10%);

    &__image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    &__details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__name {
        font-weight: bold;
    }

    &__position {

    }
}

// Previous & Next Article
.latest-news {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;

    @include breakpoint(sm) {
        flex-direction: row;
    }

    &__item {
        @include breakpoint(sm) {
            flex-basis: calc(50% - 1rem);
        }
    }

    &__previous {
        
    }

    &__next {
        text-align: right;
    }
}

.news-top {
    padding-top: var(--sticky-nav-height);
    background-color: #{$ocean-depths};
    @include breakpoint(md) {
        padding-top: var(--sticky-nav-height);
    }
}

.article-featured-image {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 56%;
    overflow: hidden;
    margin-bottom: 2rem;

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}